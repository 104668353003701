// src/hooks/useFacebookSDK.js
import { useEffect } from 'react';

const useFacebookSDK = (appId, version = 'v20.0') => {
  useEffect(() => {
    // Verifica se o SDK já está carregado
    if (window.FB) {
      window.FB.init({
        appId: appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: version,
      });
      console.log('Facebook SDK já estava carregado e foi inicializado.');
      return;
    }

    // Função de inicialização do SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: version,
      });
      console.log('Facebook SDK carregado e inicializado.');
    };

    // Carrega o SDK de forma assíncrona
    (function(d, s, id){
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    
  }, [appId, version]);
};

export default useFacebookSDK;
