import React, { useState } from 'react';
import { Plus } from 'lucide-react';

const TemplateConfig = () => {
  const [templates, setTemplates] = useState([
    {
      id: 1,
      name: 'Aniversariante do Mês - v2',
      category: 'Marketing',
      language: 'Portuguese (BR)',
      status: 'Aprovado',
    },
    {
      id: 2,
      name: 'Promoção de Verão',
      category: 'Vendas',
      language: 'English (US)',
      status: 'Reprovado',
    },
    {
      id: 3,
      name: 'Boas-vindas ao Novo Cliente',
      category: 'Atendimento',
      language: 'Portuguese (BR)',
      status: 'Aprovado',
    },
    {
      id: 4,
      name: 'Agradecimento pela Compra',
      category: 'Suporte',
      language: 'Spanish (ES)',
      status: 'Reprovado',
    },
    // Adicione mais templates conforme necessário
  ]);
  const [searchQuery, setSearchQuery] = useState('');

  // Função para filtrar mensagens modelos
  const filteredTemplates = templates.filter((template) =>
    template.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    template.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
    template.language.toLowerCase().includes(searchQuery.toLowerCase()) ||
    template.status.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Função para determinar a cor do status com base no UserConfig.js
  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'aprovado':
        return { color: '#4caf50', fontWeight: 'bold' }; // Verde
      case 'reprovado':
        return { color: '#ff6b6b', fontWeight: 'bold' }; // Vermelho
      default:
        return { color: '#fff' };
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '1000px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      {/* Cabeçalho com botão "Adicionar" */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div></div>
        <button
          onClick={() => {}}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555',
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Adicionar Novo Modelo de Mensagem"
        >
          <Plus size={20} />
          Adicionar
        </button>
      </div>

      {/* Título "Listagem dos Modelos de Mensagens" */}
      <div>
        <h3 style={{ textAlign: 'center', fontSize: '16px' }}>Listagem dos Modelos de Mensagens</h3>
      </div>

      {/* Campo de Busca */}
      <div style={{ textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Buscar modelo..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px 15px',
            borderRadius: '6px',
            border: '1px solid #555',
            width: '80%',
            maxWidth: '400px',
            fontSize: '16px',
          }}
        />
      </div>

      {/* Listagem dos Modelos de Mensagens */}
      <div>
        {filteredTemplates.length === 0 ? (
          <div style={{ textAlign: 'center' }}>Nenhum modelo de mensagem encontrado.</div>
        ) : (
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Nome</th>
                <th style={tableHeaderStyle}>Categoria</th>
                <th style={tableHeaderStyle}>Idioma</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredTemplates.map((template) => (
                <tr
                  key={template.id}
                  style={tableRowStyle}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#3a3a3a')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                >
                  <td style={tableCellStyle}>{template.name}</td>
                  <td style={tableCellStyle}>{template.category}</td>
                  <td style={tableCellStyle}>{template.language}</td>
                  <td style={{ ...tableCellStyle, ...getStatusStyle(template.status) }}>
                    {template.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

// Estilos reutilizáveis
const tableHeaderStyle = {
  borderBottom: '2px solid #444654',
  padding: '10px',
  textAlign: 'left',
  fontSize: '16px',
};

const tableRowStyle = {
  borderBottom: '1px solid #444654',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const tableCellStyle = {
  padding: '10px',
  fontSize: '14px',
};

export default TemplateConfig;
