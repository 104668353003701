// UserConfig.js

import React, { useState, useEffect } from 'react';
import { Save, Plus, X } from 'lucide-react';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const UserConfig = () => {
  // Mapeamento de perfis para exibição
  const profileDisplayNames = {
    admin: 'Administrador',
    gerente: 'Gerente',
    atendente: 'Atendente',
  };

  // Mapeamento de perfis para descrições
  const profileDescriptions = {
    admin: 'Os Administradores têm acesso completo ao sistema, podendo gerenciar todos os usuários e configurações.',
    gerente: 'Os Gerentes podem supervisionar equipes, acessar relatórios e gerenciar tarefas.',
    atendente: 'Os Atendentes podem interagir com os clientes e realizar operações diárias.',
  };

  // Estados para listagem de usuários
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersError, setUsersError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Estados para cadastro de novos usuários (Admin)
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserWhatsApp, setNewUserWhatsApp] = useState(''); // Renomeado de celular para whatsapp
  const [newUserProfile, setNewUserProfile] = useState('atendente'); // Valor interno
  const [newUserActive, setNewUserActive] = useState('Ativo'); // Novo estado para ativo com valor "Ativo"
  const [newUserLoading, setNewUserLoading] = useState(false);
  const [newUserMessage, setNewUserMessage] = useState(null);
  const [newUserErrors, setNewUserErrors] = useState({}); // Novos estados de erro

  // Estados para edição de um usuário específico
  const [editingUser, setEditingUser] = useState(null); // Objeto do usuário sendo editado
  const [editName, setEditName] = useState('');
  const [editEmail, setEditEmail] = useState(''); // Novo campo Email na edição
  const [editPassword, setEditPassword] = useState('');
  const [editWhatsApp, setEditWhatsApp] = useState(''); // Renomeado de celular para whatsapp na edição
  const [editProfile, setEditProfile] = useState('atendente'); // Valor interno
  const [editActive, setEditActive] = useState('Ativo'); // Novo estado para ativo na edição com valor "Ativo"
  const [editLoading, setEditLoading] = useState(false);
  const [editMessage, setEditMessage] = useState(null);
  const [editUserErrors, setEditUserErrors] = useState({}); // Novos estados de erro

  // Estado para simular ser admin (apenas para desenvolvimento)
  const [isAdmin, setIsAdmin] = useState(true); // Defina como 'true' para testes

  const authInstance = getAuth();

  // Função para formatar data no formato brasileiro com fuso horário de São Paulo
  const formatDateToBrazilian = (dateString) => {
    if (!dateString || dateString === 'Nunca' || dateString === 'Indisponível') {
      return dateString;
    }
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Data inválida';
    }
  
    // Opções de formatação com fuso horário
    const options = {
      timeZone: 'America/Sao_Paulo', // Define o fuso horário para São Paulo
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Formato 24 horas
    };
  
    // Formata a data usando Intl.DateTimeFormat
    const formatter = new Intl.DateTimeFormat('pt-BR', options);
    return formatter.format(date);
  };  

  useEffect(() => {
    const fetchUsers = async () => {
      setUsersLoading(true);
      setUsersError(null);
      try {
        const currentUser = authInstance.currentUser;
        if (!currentUser) {
          setUsersError('Usuário não autenticado.');
          setUsersLoading(false);
          return;
        }

        const idToken = await currentUser.getIdToken();
        const config = {
          headers: { Authorization: `Bearer ${idToken}` },
        };

        const response = await axios.get('https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/get-users', config);
        const fetchedUsersRaw = response.data;

        // Processar usuários para formatar a data e incluir o status online
        const fetchedUsers = fetchedUsersRaw.map(user => {
          return {
            ...user,
            lastLoginFormatted: formatDateToBrazilian(user.lastLogin),
            online: user.online || false, // Incluir o campo online, default para false
          };
        });

        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        setUsersError('Erro ao buscar usuários. Por favor, tente novamente.');
      } finally {
        setUsersLoading(false);
      }
    };

    if (isAdmin) {
      fetchUsers();
    } else {
      setUsersError('Acesso negado. Usuário não é administrador.');
      setUsersLoading(false);
    }
  }, [authInstance, isAdmin]);

  // Função para validar a senha
  const validatePassword = (password) => {
    // Pelo backend: pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&])[A-Za-z\d@$!%*?#&]{8,}$/;
    return regex.test(password);
  };

  // Função para validar número de telefone
  const validatePhoneNumber = (number) => {
    const cleanedNumber = number.replace(/\D/g, '');
    return cleanedNumber.length >= 10 && cleanedNumber.length <= 15;
  };

  // Função para converter status de string para booleano
  const convertStatusToBoolean = (status) => {
    return status === 'Ativo';
  };

  // Função para converter booleano para status de string
  const convertBooleanToStatus = (active) => {
    return active ? 'Ativo' : 'Desativado';
  };

  // Função para cadastrar um novo usuário (Admin)
  const handleCreateUser = async () => {
    // Resetar mensagens de erro e sucesso
    setNewUserMessage(null);
    setNewUserErrors({}); // Resetar erros

    // Validações dos campos
    const newError = {};

    if (!newUserName.trim()) {
      newError.newUserName = 'Por favor, insira o nome do usuário.';
    }

    if (!newUserEmail.trim()) {
      newError.newUserEmail = 'Por favor, insira o email do usuário.';
    }

    if (!newUserPassword.trim()) {
      newError.newUserPassword = 'Por favor, insira a senha do usuário.';
    } else if (!validatePassword(newUserPassword)) {
      newError.newUserPassword = 'A senha deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.';
    }

    if (!newUserWhatsApp.trim()) {
      newError.newUserWhatsApp = 'Por favor, insira o número de WhatsApp do usuário.';
    } else if (!validatePhoneNumber(newUserWhatsApp)) {
      newError.newUserWhatsApp = 'Por favor, insira um número de WhatsApp válido.';
    }

    if (Object.keys(newError).length > 0) {
      setNewUserErrors(newError); // Definir erros específicos
      return;
    }

    setNewUserLoading(true);
    setNewUserMessage(null);

    try {
      const currentUser = authInstance.currentUser;

      if (!currentUser) {
        setNewUserMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
        setNewUserLoading(false);
        return;
      }

      // Obter o ID Token para autenticar a requisição no backend
      const idToken = await currentUser.getIdToken();

      const config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };

      // Criar o usuário no backend
      const createUserResponse = await axios.post(
        'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/create-user',
        {
          displayName: newUserName,
          email: newUserEmail,
          password: newUserPassword,
          whatsapp: newUserWhatsApp, // Enviando 'whatsapp' como esperado pelo backend
          profile: newUserProfile, // Valores internos: 'admin', 'gerente', 'atendente'
          active: convertStatusToBoolean(newUserActive), // Enviando o estado de ativo como booleano
        },
        config
      );

      if (createUserResponse.status === 201) {
        const newUser = {
          uid: createUserResponse.data.uid,
          displayName: newUserName,
          email: newUserEmail,
          profile: newUserProfile,
          whatsapp: newUserWhatsApp,
          active: convertStatusToBoolean(newUserActive), // Adicionando o status de ativo
          lastLoginFormatted: 'Nunca',
          online: false,
        };

        // Atualizar a lista de usuários e ordenar alfabeticamente
        const updatedUsers = [...users, newUser].sort((a, b) =>
          a.displayName.localeCompare(b.displayName)
        );
        setUsers(updatedUsers);
        setNewUserMessage({ type: 'success', text: 'Novo usuário criado com sucesso!' });
        // Limpar os campos após o sucesso
        setNewUserName('');
        setNewUserEmail('');
        setNewUserPassword('');
        setNewUserWhatsApp('');
        setNewUserProfile('atendente'); // Resetar para o valor padrão
        setNewUserActive('Ativo'); // Resetar para "Ativo"
        setShowCreateForm(false);
      } else {
        setNewUserMessage({ type: 'error', text: 'Erro ao criar usuário. Por favor, tente novamente.' });
      }
    } catch (error) {
      console.error('Erro ao criar novo usuário:', error);
      let errorText = 'Erro ao criar novo usuário. Por favor, tente novamente.';
      if (error.response && error.response.data && error.response.data.error) {
        errorText = error.response.data.error;
      }
      setNewUserMessage({ type: 'error', text: errorText });
    } finally {
      setNewUserLoading(false);
    }
  };

  // Função para iniciar a edição de um usuário
  const startEditingUser = (user) => {
    setEditingUser(user);
    setEditName(user.displayName || '');
    setEditEmail(user.email || ''); // Preencher o Email atual
    setEditPassword(''); // Deixe a senha em branco
    setEditWhatsApp(user.whatsapp || ''); // Renomeado de celular para whatsapp na edição
    setEditProfile(user.profile || 'atendente'); // Valor interno
    setEditActive(convertBooleanToStatus(user.active)); // Inicializar com o estado atual ou "Ativo"
    setEditMessage(null);
    setEditUserErrors({}); // Resetar erros
  };

  // Função para cancelar a edição
  const cancelEditing = () => {
    setEditingUser(null);
    setEditName('');
    setEditEmail('');
    setEditPassword('');
    setEditWhatsApp('');
    setEditProfile('atendente');
    setEditActive('Ativo');
    setEditMessage(null);
    setEditUserErrors({}); // Resetar erros
  };

  // Função para atualizar um usuário específico
  const handleUpdateUser = async () => {
    // Resetar mensagens de erro e sucesso
    setEditMessage(null);
    setEditUserErrors({}); // Resetar erros

    // Validações dos campos
    const editError = {};

    if (!editName.trim()) {
      editError.editName = 'Por favor, preencha o nome.';
    }

    if (!editEmail.trim()) {
      editError.editEmail = 'Por favor, preencha o email.';
    }

    if (editPassword && !validatePassword(editPassword)) {
      editError.editPassword = 'A senha deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.';
    }

    if (!editWhatsApp.trim()) {
      editError.editWhatsApp = 'Por favor, insira o número de WhatsApp do usuário.';
    } else if (!validatePhoneNumber(editWhatsApp)) {
      editError.editWhatsApp = 'Por favor, insira um número de WhatsApp válido.';
    }

    if (Object.keys(editError).length > 0) {
      setEditUserErrors(editError); // Definir erros específicos
      return;
    }

    setEditLoading(true);
    setEditMessage(null);

    try {
      const currentUser = authInstance.currentUser;

      if (!currentUser) {
        setEditMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
        setEditLoading(false);
        return;
      }

      // Obter o ID Token para autenticar a requisição no backend
      const idToken = await currentUser.getIdToken();

      const config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };

      const payload = {
        uid: editingUser.uid,
        displayName: editName,
        email: editEmail, // Incluir o email
        password: editPassword || undefined, // Enviar somente se houver
        whatsapp: editWhatsApp, // Enviando 'whatsapp' como esperado pelo backend
        profile: editProfile, // Valor interno
        active: convertStatusToBoolean(editActive), // Enviando o estado de ativo como booleano
      };

      const response = await axios.put(
        'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/update-user',
        payload,
        config
      );

      if (response.status === 200) {
        // Atualizar a lista de usuários
        const updatedUsers = users
          .map((user) =>
            user.uid === editingUser.uid
              ? {
                  ...user,
                  displayName: editName,
                  email: editEmail,
                  profile: editProfile,
                  whatsapp: editWhatsApp,
                  active: convertStatusToBoolean(editActive),
                }
              : user
          )
          .sort((a, b) => a.displayName.localeCompare(b.displayName));

        setUsers(updatedUsers);
        setEditMessage({ type: 'success', text: 'Usuário atualizado com sucesso!' });
        // Cancelar a edição
        cancelEditing();
      } else {
        setEditMessage({ type: 'error', text: 'Erro ao atualizar usuário. Por favor, tente novamente.' });
      }
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      let errorText = 'Erro ao atualizar usuário. Por favor, tente novamente.';
      if (error.response && error.response.data && error.response.data.error) {
        errorText = error.response.data.error;
      }
      setEditMessage({ type: 'error', text: errorText });
    } finally {
      setEditLoading(false);
    }
  };

  // Função para filtrar usuários com base na busca
  const filteredUsers = users.filter((user) =>
    user.displayName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '1000px', // Aumentado para acomodar listagem e formulários
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px', // Espaçamento entre seções
      }}
    >
      {/* Cabeçalho com botão "Adicionar" */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Título removido conforme solicitado */}
        <div></div>
        <button
          onClick={() => setShowCreateForm(true)}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555', // Adicionado border
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Adicionar Usuário"
        >
          <Plus size={20} />
          Adicionar
        </button>
      </div>

      {/* Título "Listagem de Usuários" */}
      <div>
        <h3 style={{ textAlign: 'center', fontSize: '16px' }}>Listagem de Usuários</h3>
      </div>

      {/* Campo de Busca */}
      <div style={{ textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Buscar usuário..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px 15px',
            borderRadius: '6px',
            border: '1px solid #555',
            width: '80%',
            maxWidth: '400px',
            fontSize: '16px',
          }}
        />
      </div>

      {/* Seção de Listagem de Usuários */}
      <div>
        {usersLoading ? (
          <div style={{ textAlign: 'center' }}>Carregando usuários...</div>
        ) : usersError ? (
          <div style={{ textAlign: 'center', color: '#ff6b6b' }}>{usersError}</div>
        ) : (
          <div>
            {filteredUsers.length === 0 ? (
              <div style={{ textAlign: 'center' }}>Nenhum usuário encontrado.</div>
            ) : (
              <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyle}>Usuário</th>
                    <th style={tableHeaderStyle}>Perfil de Acesso</th>
                    <th style={tableHeaderStyle}>Status</th> {/* Renomeado para "Status" */}
                    <th style={tableHeaderStyle}>Último Login</th> {/* Último Login como última coluna */}
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user) => (
                    <tr
                      key={user.uid}
                      style={tableRowStyle}
                      onClick={() => startEditingUser(user)}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#3a3a3a')}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                      aria-label={`Selecionar usuário ${user.displayName}`}
                    >
                      <td style={tableCellStyle}>{user.displayName || 'Sem Nome'}</td>
                      <td style={tableCellStyle}>{profileDisplayNames[user.profile] || 'Atendente'}</td>
                      <td style={tableCellStyle}>
                        {user.active ? (
                          <span style={{ color: '#4caf50', fontWeight: 'bold' }}>Ativo</span>
                        ) : (
                          <span style={{ color: '#ff6b6b', fontWeight: 'bold' }}>Desativado</span>
                        )}
                      </td>
                      <td style={tableCellStyle}>
                        {user.online ? (
                          <span style={{ color: '#4caf50', fontWeight: 'bold' }}>Online</span>
                        ) : (
                          user.lastLoginFormatted || 'Nunca'
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      {/* Modal para Cadastro de Novo Usuário (Admin) */}
      {showCreateForm && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            {/* Cabeçalho da Modal */}
            <div style={modalHeaderStyle}>
              <h2 style={{ fontSize: '18px', margin: 0 }}>Cadastro de Novo Usuário</h2>
              <button
                onClick={() => {
                  setShowCreateForm(false);
                  setNewUserName('');
                  setNewUserEmail('');
                  setNewUserPassword('');
                  setNewUserWhatsApp('');
                  setNewUserProfile('atendente');
                  setNewUserActive('Ativo');
                  setNewUserMessage(null);
                  setNewUserErrors({});
                }}
                style={closeButtonStyle}
                aria-label="Fechar cadastro"
              >
                <X size={20} />
              </button>
            </div>

            {/* Corpo da Modal */}
            <div style={modalBodyStyle}>
              {/* Contêiner Flexível para Nome e WhatsApp */}
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px' }}>
                {/* Campo Nome */}
                <div style={{ flex: '2', minWidth: '200px' }}>
                  <label htmlFor="new-user-name" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Nome
                  </label>
                  <input
                    id="new-user-name"
                    type="text"
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                    placeholder="Nome do novo usuário"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${newUserErrors.newUserName ? '#ff6b6b' : '#555'}`, // Borda vermelha se houver erro
                      width: '100%',
                      fontSize: '14px', // Reduzido de 16px para 14px
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {newUserErrors.newUserName && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{newUserErrors.newUserName}</small>
                  )}
                </div>

                {/* Campo WhatsApp */}
                <div style={{ flex: '1', minWidth: '200px' }}>
                  <label htmlFor="new-user-whatsapp" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    WhatsApp
                  </label>
                  <input
                    id="new-user-whatsapp"
                    type="text"
                    value={newUserWhatsApp}
                    onChange={(e) => setNewUserWhatsApp(e.target.value)}
                    placeholder="+5511999999999" // Apenas o exemplo no placeholder
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${newUserErrors.newUserWhatsApp ? '#ff6b6b' : '#555'}`, // Borda vermelha se houver erro
                      width: '100%',
                      fontSize: '14px', // Reduzido de 16px para 14px
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {newUserErrors.newUserWhatsApp && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{newUserErrors.newUserWhatsApp}</small>
                  )}
                </div>
              </div>

              {/* Campo Email */}
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="new-user-email" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Email
                </label>
                <input
                  id="new-user-email"
                  type="email"
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  placeholder="Email do novo usuário"
                  style={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '12px',
                    borderRadius: '6px',
                    border: `1px solid ${newUserErrors.newUserEmail ? '#ff6b6b' : '#555'}`, // Borda vermelha se houver erro
                    width: '100%',
                    fontSize: '14px', // Reduzido de 16px para 14px
                    transition: 'border-color 0.3s',
                  }}
                />
                {newUserErrors.newUserEmail && (
                  <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{newUserErrors.newUserEmail}</small>
                )}
              </div>

              {/* Contêiner Flexível para Perfil de Acesso e Senha */}
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px' }}>
                {/* Campo Perfil de Acesso */}
                <div style={{ flex: '1 1 200px', minWidth: '200px' }}>
                  <label htmlFor="new-user-profile" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Perfil de Acesso
                  </label>
                  <select
                    id="new-user-profile"
                    value={newUserProfile}
                    onChange={(e) => setNewUserProfile(e.target.value)}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      width: '100%',
                      fontSize: '14px', // Reduzido de 16px para 14px
                    }}
                  >
                    <option value="admin">Administrador</option>
                    <option value="gerente">Gerente</option>
                    <option value="atendente">Atendente</option>
                  </select>
                  {/* Descrição do Perfil Selecionado */}
                  {newUserProfile && (
                    <small style={{ color: '#aaa', fontSize: '14px', display: 'block', marginTop: '4px' }}>
                      {profileDescriptions[newUserProfile]}
                    </small>
                  )}
                </div>

                {/* Campo Senha */}
                <div style={{ flex: '1 1 200px', minWidth: '200px' }}>
                  <label htmlFor="new-user-password" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Senha
                  </label>
                  <input
                    id="new-user-password"
                    type="password"
                    value={newUserPassword}
                    onChange={(e) => setNewUserPassword(e.target.value)}
                    placeholder="Senha do novo usuário"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${newUserErrors.newUserPassword ? '#ff6b6b' : '#555'}`, // Borda vermelha se houver erro
                      width: '100%',
                      fontSize: '14px', // Reduzido de 16px para 14px
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {newUserErrors.newUserPassword && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{newUserErrors.newUserPassword}</small>
                  )}
                  <small style={{ color: '#aaa', fontSize: '14px', display: 'block', marginTop: '4px' }}>
                    A senha deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.
                  </small>
                </div>
              </div>

              {/* Contêiner Flexível para Status e Botão Cadastrar */}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Campo Status */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <label htmlFor="new-user-active" style={{ fontSize: '14px' }}>
                    Status
                  </label>
                  <select
                    id="new-user-active"
                    value={newUserActive}
                    onChange={(e) => setNewUserActive(e.target.value)}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '8px 12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      fontSize: '14px',
                      flex: '1',
                      width: '150px', // Aumenta a largura do seletor
                      height: '40px', // Aumenta a altura do seletor
                    }}
                  >
                    <option value="Ativo">Ativo</option>
                    <option value="Desativado">Desativado</option>
                  </select>
                </div>

                {/* Botão Cadastrar */}
                <button
                  onClick={handleCreateUser}
                  style={{
                    backgroundColor: '#242424',
                    color: '#e5e7eb',
                    padding: '10px 20px',
                    border: '1px solid #555', // Adicionado border
                    borderRadius: '6px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    fontSize: '14px',
                    transition: 'background-color 0.3s, border 0.3s',
                  }}
                  disabled={newUserLoading}
                  aria-label="Cadastrar novo usuário"
                >
                  <Save size={16} />
                  {newUserLoading ? 'Cadastrando...' : 'Cadastrar'}
                </button>
              </div>

              {newUserMessage && (
                <div
                  style={{
                    marginTop: '10px',
                    color: newUserMessage.type === 'error' ? '#ff6b6b' : '#4caf50',
                    fontSize: '14px', // Reduzido de 16px para 14px
                    textAlign: 'center',
                  }}
                >
                  {newUserMessage.text}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Modal para Edição de Usuário */}
      {editingUser && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            {/* Cabeçalho da Modal */}
            <div style={modalHeaderStyle}>
              <h2 style={{ fontSize: '18px', margin: 0 }}>Edição de Usuário</h2>
              <button
                onClick={() => {
                  cancelEditing();
                  setEditName('');
                  setEditEmail('');
                  setEditPassword('');
                  setEditWhatsApp('');
                  setEditProfile('atendente');
                  setEditActive('Ativo');
                  setEditMessage(null);
                  setEditUserErrors({});
                }}
                style={closeButtonStyle}
                aria-label="Fechar edição"
              >
                <X size={20} />
              </button>
            </div>

            {/* Corpo da Modal */}
            <div style={modalBodyStyle}>
              {/* Contêiner Flexível para Nome e WhatsApp */}
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px' }}>
                {/* Campo Nome */}
                <div style={{ flex: '2', minWidth: '200px' }}>
                  <label htmlFor="edit-user-name" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Nome
                  </label>
                  <input
                    id="edit-user-name"
                    type="text"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    placeholder="Nome do usuário"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${editUserErrors.editName ? '#ff6b6b' : '#555'}`, // Borda vermelha se houver erro
                      width: '100%',
                      fontSize: '14px', // Reduzido de 16px para 14px
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {editUserErrors.editName && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{editUserErrors.editName}</small>
                  )}
                </div>

                {/* Campo WhatsApp */}
                <div style={{ flex: '1', minWidth: '200px' }}>
                  <label htmlFor="edit-user-whatsapp" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    WhatsApp
                  </label>
                  <input
                    id="edit-user-whatsapp"
                    type="text"
                    value={editWhatsApp}
                    onChange={(e) => setEditWhatsApp(e.target.value)}
                    placeholder="+5511999999999" // Apenas o exemplo no placeholder
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${editUserErrors.editWhatsApp ? '#ff6b6b' : '#555'}`, // Borda vermelha se houver erro
                      width: '100%',
                      fontSize: '14px', // Reduzido de 16px para 14px
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {editUserErrors.editWhatsApp && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{editUserErrors.editWhatsApp}</small>
                  )}
                </div>
              </div>

              {/* Campo Email */}
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="edit-user-email" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Email
                </label>
                <input
                  id="edit-user-email"
                  type="email"
                  value={editEmail}
                  onChange={(e) => setEditEmail(e.target.value)}
                  placeholder="Email do usuário"
                  style={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '12px',
                    borderRadius: '6px',
                    border: `1px solid ${editUserErrors.editEmail ? '#ff6b6b' : '#555'}`, // Borda vermelha se houver erro
                    width: '100%',
                    fontSize: '14px', // Reduzido de 16px para 14px
                    transition: 'border-color 0.3s',
                  }}
                />
                {editUserErrors.editEmail && (
                  <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{editUserErrors.editEmail}</small>
                )}
              </div>

              {/* Contêiner Flexível para Perfil de Acesso e Senha */}
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px' }}>
                {/* Campo Perfil de Acesso */}
                <div style={{ flex: '1 1 200px', minWidth: '200px' }}>
                  <label htmlFor="edit-user-profile" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Perfil de Acesso
                  </label>
                  <select
                    id="edit-user-profile"
                    value={editProfile}
                    onChange={(e) => setEditProfile(e.target.value)}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      width: '100%',
                      fontSize: '14px', // Reduzido de 16px para 14px
                    }}
                  >
                    <option value="admin">Administrador</option>
                    <option value="gerente">Gerente</option>
                    <option value="atendente">Atendente</option>
                  </select>
                  {/* Descrição do Perfil Selecionado */}
                  {editProfile && (
                    <small style={{ color: '#aaa', fontSize: '14px', display: 'block', marginTop: '4px' }}>
                      {profileDescriptions[editProfile]}
                    </small>
                  )}
                </div>

                {/* Campo Senha */}
                <div style={{ flex: '1 1 200px', minWidth: '200px' }}>
                  <label htmlFor="edit-user-password" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Senha
                  </label>
                  <input
                    id="edit-user-password"
                    type="password"
                    value={editPassword}
                    onChange={(e) => setEditPassword(e.target.value)}
                    placeholder="Nova senha"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${editUserErrors.editPassword ? '#ff6b6b' : '#555'}`, // Borda vermelha se houver erro
                      width: '100%',
                      fontSize: '14px', // Reduzido de 16px para 14px
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {editUserErrors.editPassword && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{editUserErrors.editPassword}</small>
                  )}
                  <small style={{ color: '#aaa', fontSize: '14px', display: 'block', marginTop: '4px' }}>
                    A senha deve ter no mínimo 8 caracteres, com letra maiúscula, minúscula, número e caractere especial.
                  </small>
                </div>
              </div>

              {/* Contêiner Flexível para Status e Botão Atualizar */}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Campo Status */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <label htmlFor="edit-user-active" style={{ fontSize: '14px' }}>
                    Status
                  </label>
                  <select
                    id="edit-user-active"
                    value={editActive}
                    onChange={(e) => setEditActive(e.target.value)}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '8px 12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      fontSize: '14px',
                      width: '150px', // Aumenta a largura do seletor
                      height: '40px', // Aumenta a altura do seletor
                    }}
                  >
                    <option value="Ativo">Ativo</option>
                    <option value="Desativado">Desativado</option>
                  </select>
                </div>

                {/* Botão Atualizar */}
                <button
                  onClick={handleUpdateUser}
                  style={{
                    backgroundColor: '#242424',
                    padding: '10px 20px',
                    color: '#e5e7eb',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    border: '1px solid #555',
                    fontSize: '14px', // Reduzido de 16px para 14px
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    transition: 'background-color 0.3s, border 0.3s',
                  }}
                  disabled={editLoading}
                  aria-label="Atualizar usuário"
                >
                  <Save size={16} />
                  {editLoading ? 'Atualizando...' : 'Atualizar'}
                </button>
              </div>

              {editMessage && (
                <div
                  style={{
                    marginTop: '10px',
                    color: editMessage.type === 'error' ? '#ff6b6b' : '#4caf50',
                    fontSize: '14px', // Reduzido de 16px para 14px
                    textAlign: 'center',
                  }}
                >
                  {editMessage.text}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Estilos reutilizáveis
const tableHeaderStyle = {
  borderBottom: '2px solid #444654',
  padding: '10px',
  textAlign: 'left',
  fontSize: '16px', // Mantido em 16px conforme solicitado apenas para títulos de cadastro e edição
};

const tableRowStyle = {
  borderBottom: '1px solid #444654',
  cursor: 'pointer', // Indica que a linha é clicável
  transition: 'background-color 0.3s',
};

const tableCellStyle = {
  padding: '10px',
  fontSize: '14px', // Ajustado para 14px
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  overflowY: 'auto', // Permite scroll caso o conteúdo exceda a altura da tela
};

const modalContentStyle = {
  backgroundColor: '#242424',
  padding: '0px', // Removido padding geral
  borderRadius: '12px',
  width: '90%',
  maxWidth: '800px',
  maxHeight: '90vh', // Limita a altura da modal para 90% da altura da viewport
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative', // Para posicionar o botão X
  overflow: 'hidden', // Oculta qualquer excesso
};

const modalHeaderStyle = {
  padding: '20px',
  borderBottom: '1px solid #444654',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const modalBodyStyle = {
  padding: '20px',
  overflowY: 'auto', // Permite scroll interno caso o conteúdo exceda a altura da modal
  flex: '1 1 auto', // Faz o corpo ocupar o espaço restante
};

const closeButtonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
};

export default UserConfig;
