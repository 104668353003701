// LoginScreen.js

import React, { useState } from 'react';
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  sendPasswordResetEmail, 
  GoogleAuthProvider, 
  signInWithPopup,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Icon from '../assets/Icon.png';

const GoogleLogo = ({ size = 24 }) => (
  <svg viewBox="0 0 24 24" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
  </svg>
);

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar senha
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  // Função para voltar à página de planos
  const handleBackToPlans = () => {
    window.location.assign('https://www.iatconsulting.com.br/pre%C3%A7os');
  };
  
  // Login com email e senha
  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      navigate('/chat');
      
    } catch (error) {
      console.error(error); // Loga o erro no console para depuração

      // Resetar mensagens anteriores
      setError('');
      setMessage('');

      // Verificar o código de erro
      if (error.code === 'auth/wrong-password') {
        setError('Senha incorreta. Tente novamente.');
      } else if (error.code === 'auth/user-not-found') {
        setError('Usuário não encontrado. Por favor, cadastre-se.');
      } else if (error.code === 'auth/too-many-requests') {
        setError('Muitas tentativas de login. Tente novamente mais tarde.');
      } 
      // Verificar mensagens de erro específicas retornadas pelas Funções de Bloqueio
      else if (error.message && error.message.includes('CPF ou CNPJ inválido')) {
        setError('CPF ou CNPJ inválido. Por favor, verifique e tente novamente.');
      } else if (error.message && error.message.includes('Número de WhatsApp inválido')) {
        setError('Número de WhatsApp inválido. Por favor, verifique e tente novamente.');
      } else if (error.message && error.message.includes('Conta está desativada')) {
        setError('Sua conta está desativada. Por favor, contate o suporte.');
      } else if (error.message && error.message.includes('Usuário está banido')) {
        setError('Sua conta foi banida. Por favor, contate o suporte.');
      } else {
        setError('Erro ao fazer login. Verifique suas credenciais.');
      }
    }
  };

  // Login com Google
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Verifica o UID retornado
      console.log('Usuário autenticado com UID:', user.uid);
  
      if (user) {
        // Após o login bem-sucedido, redireciona o usuário para a página de chat
        navigate('/chat');
      } else {
        setError('Erro ao fazer login com Google. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao fazer login com Google:', error);
      setError('Erro ao fazer login com Google. Tente novamente.');
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Por favor, insira seu endereço de e-mail.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Um e-mail para redefinição de senha foi enviado.');
      setError('');
    } catch (error) {
      console.error(error); // Loga o erro no console para depuração

      // Resetar mensagens anteriores
      setError('');
      setMessage('');

      if (error.code === 'auth/user-not-found') {
        setError('Esse e-mail não está cadastrado. Por favor, verifique ou faça o cadastro.');
      } else {
        setError('Erro ao enviar e-mail de redefinição de senha. Verifique se o e-mail está correto.');
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#242424] p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 relative">
        {/* Botão para voltar à página de planos */}
        <button
          onClick={handleBackToPlans}
          className="absolute top-4 left-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Voltar para página de planos"
        >
          <i className="fas fa-arrow-left text-xl"></i>
        </button>
        <div className="flex justify-center mb-6">
          <img src={Icon} alt="Logo" width={48} height={48} />
        </div>

        <form onSubmit={handleEmailLogin} className="space-y-4">
          {error && <div className="text-red-500">{error}</div>}
          {message && <div className="text-green-500">{message}</div>} {/* Exibe mensagem de sucesso */}

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Endereço de e-mail*
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Senha*
            </label>
            <input
              type={showPassword ? 'text' : 'password'} // Alterna entre 'text' e 'password'
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)} // Alterna a visibilidade da senha
              className="absolute right-2 top-9"
            >
              {showPassword ? (
                <i className="fas fa-eye-slash text-gray-500" style={{ fontSize: '20px' }}></i> // Ícone de olho fechado em cinza
              ) : (
                <i className="fas fa-eye text-gray-500" style={{ fontSize: '20px' }}></i> // Ícone de olho aberto em cinza
              )}
            </button>
          </div>

          <button type="submit" className="w-full bg-[#f1c915] hover:bg-[#242424] text-white py-2 rounded">
            Continuar
          </button>
        </form>

        {/* Link para recuperação de senha */}
        <div className="mt-4 text-center">
          <button
            onClick={handleForgotPassword}
            className="text-sm text-[#242424] hover:underline"
          >
            Esqueci minha senha
          </button>
        </div>

        <div className="mt-4 text-center">
          <span className="text-sm text-gray-600">Não tem uma conta? </span>
          <a href="https://www.iatconsulting.com.br/pre%C3%A7os" className="text-sm text-[#242424] hover:underline">Cadastrar</a>
        </div>

        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">ou</span>
          </div>
        </div>

        <div className="mt-6">
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full flex items-center justify-center space-x-2 border border-gray-300 py-2 rounded"
          >
            <GoogleLogo size={24} />
            <span>Login com o Google</span>
          </button>
        </div>

        <div className="mt-6 text-center text-xs text-gray-500">
          <a href="https://www.iatconsulting.com.br/termos-condicoes" target="_blank" rel="noopener noreferrer" className="hover:underline">Termos de uso</a>
          {' | '}
          <a href="https://www.iatconsulting.com.br/politica-privacidade" target="_blank" rel="noopener noreferrer" className="hover:underline">Política de privacidade</a>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
