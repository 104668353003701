import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ChatInterface from './components/ChatInterface'; // Componente de chat
import ChatSettings from './components/ChatSettings'; // Componente de configuração
import LoginScreen from './components/LoginScreen'; // Componente de login
import RegisterScreen from './components/RegisterScreen'; // Componente de cadastro
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        {/* Rota de Login */}
        <Route path="/login" element={<LoginScreen />} />

        {/* Rota de Cadastro */}
        <Route path="/register" element={<RegisterScreen />} />

        {/* Rota de Chat */}
        <Route path="/chat" element={<ChatInterface />} />

        {/* Rota de Configurações */}
        <Route path="/settings" element={<ChatSettings />} />

        {/* Redireciona a raiz para /login */}
        <Route path="/" element={<Navigate to="/login" replace />} />

        {/* Redireciona qualquer rota não definida para /login */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
