// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC4Oy19YD021otKPHZrZZoaZ2BC5xp25ic",
  authDomain: "iat-consulting.firebaseapp.com",
  projectId: "iat-consulting",
  storageBucket: "iat-consulting.appspot.com",
  messagingSenderId: "145011075010",
  appId: "1:145011075010:web:3b3beeab085e8fe80cd85b",
  measurementId: "G-Y3Q455WQ2Y"
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Certifique-se de usar o `app` aqui
const db = getFirestore(app);

export { auth, db };
