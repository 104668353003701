// src/components/ChatInterface.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom'; // Necessário para portais
import {
  MoreVertical, Search, Smile, X,
  Paperclip, Send, Mic, StopCircle, Settings, LogOut, XCircle, CheckCircle, Sparkles, Building,
  Volume2, VolumeX, Vibrate, Clipboard, Pen, Trash, FileText, Mic2, UserPlus, ChevronDown, Lock, User,
  Archive,
} from 'lucide-react';
import { FaExchangeAlt } from 'react-icons/fa'; // Importando o ícone alternativo
import { useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios'; // Importando axios para chamadas de API
import { auth } from '../firebase';
import EmojiPicker from 'emoji-picker-react';
import PhoneInput from 'react-phone-input-2'; // Importando PhoneInput
import 'react-phone-input-2/lib/style.css'; // Importando estilos padrão do PhoneInput
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown'; // Importando react-markdown para renderizar Markdown
import iconBackground from '../assets/Icon_gray.svg'; // Importando o ícone de fundo

// Definição da lista de países
const COUNTRIES = [
  { name: 'Afeganistão', code: '+93', countryCode: 'AF' },
  { name: 'África do Sul', code: '+27', countryCode: 'ZA' },
  { name: 'Albânia', code: '+355', countryCode: 'AL' },
  { name: 'Alemanha', code: '+49', countryCode: 'DE' },
  { name: 'Argélia', code: '+213', countryCode: 'DZ' },
  { name: 'Argentina', code: '+54', countryCode: 'AR' },
  { name: 'Austrália', code: '+61', countryCode: 'AU' },
  { name: 'Áustria', code: '+43', countryCode: 'AT' },
  { name: 'Bélgica', code: '+32', countryCode: 'BE' },
  { name: 'Bolívia', code: '+591', countryCode: 'BO' },
  { name: 'Brasil', code: '+55', countryCode: 'BR' },
  { name: 'Canadá', code: '+1', countryCode: 'CA' },
  { name: 'Chile', code: '+56', countryCode: 'CL' },
  { name: 'China', code: '+86', countryCode: 'CN' },
  { name: 'Colômbia', code: '+57', countryCode: 'CO' },
  { name: 'Coreia do Sul', code: '+82', countryCode: 'KR' },
  { name: 'Dinamarca', code: '+45', countryCode: 'DK' },
  { name: 'Egito', code: '+20', countryCode: 'EG' },
  { name: 'Emirados Árabes Unidos', code: '+971', countryCode: 'AE' },
  { name: 'Espanha', code: '+34', countryCode: 'ES' },
  { name: 'Estados Unidos', code: '+1', countryCode: 'US' },
  { name: 'França', code: '+33', countryCode: 'FR' },
  { name: 'Grécia', code: '+30', countryCode: 'GR' },
  { name: 'Índia', code: '+91', countryCode: 'IN' },
  { name: 'Itália', code: '+39', countryCode: 'IT' },
  { name: 'Japão', code: '+81', countryCode: 'JP' },
  { name: 'México', code: '+52', countryCode: 'MX' },
  { name: 'Portugal', code: '+351', countryCode: 'PT' },
  { name: 'Reino Unido', code: '+44', countryCode: 'GB' },
  { name: 'Rússia', code: '+7', countryCode: 'RU' },
  { name: 'Suíça', code: '+41', countryCode: 'CH' },
  { name: 'Turquia', code: '+90', countryCode: 'TR' },
  { name: 'Uruguai', code: '+598', countryCode: 'UY' },
  { name: 'Venezuela', code: '+58', countryCode: 'VE' },
];

// Extrair apenas os countryCodes em minúsculas para o 'onlyCountries'
const ONLY_COUNTRIES = COUNTRIES.map(country => country.countryCode.toLowerCase());

// Componente para Selecionar Templates com campo de busca e ícone de lupa
const TemplateSelector = ({ templates, loading, onSelect, onClose }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrar templates com base no termo de busca
  const filteredTemplates = templates.filter(template =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return ReactDOM.createPortal(
    <div style={modalOverlayStyle}>
      <div style={{ ...modalContentStyle, maxHeight: '80vh', overflowY: 'auto' }}>
        {/* Botão "X" para fechar a modal */}
        <button
          onClick={onClose}
          style={closeButtonStyle}
          aria-label="Fechar seleção de template"
        >
          <X size={20} />
        </button>

        {/* Cabeçalho atualizado */}
        <h2 style={{ textAlign: 'center', color: '#fff', marginBottom: '20px' }}>
          Selecionar Modelo de Mensagem
        </h2>

        {/* Campo de Busca com ícone de lupa */}
        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <input
            type="text"
            placeholder="Buscar modelo..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '10px 10px 10px 40px', // Adiciona padding-left para o ícone
              borderRadius: '6px',
              border: '1px solid #555',
              backgroundColor: '#333',
              color: '#fff',
              fontSize: '16px',
            }}
            aria-label="Buscar modelo de mensagem"
          />
          {/* Ícone de lupa posicionado dentro do campo de busca */}
          <button
            style={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'none',
              border: 'none',
              color: '#ccc',
              cursor: 'pointer',
            }}
            aria-label="Buscar"
          >
            <Search size={20} />
          </button>
        </div>

        {loading ? (
          <p className="text-center text-gray-400">Carregando templates...</p>
        ) : filteredTemplates.length === 0 ? (
          <p className="text-center text-gray-400">Nenhum template encontrado.</p>
        ) : (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {filteredTemplates.map(template => (
              <li
                key={template.id}
                style={{
                  backgroundColor: selectedTemplate?.id === template.id ? '#444' : '#333',
                  padding: '15px',
                  borderRadius: '8px',
                  marginBottom: '10px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s, border 0.3s',
                  border: selectedTemplate?.id === template.id ? '2px solid #555555' : '2px solid transparent',
                }}
                onClick={() => setSelectedTemplate(template)}
                aria-label={`Selecionar template ${template.name}`}
                onMouseEnter={e => e.currentTarget.style.backgroundColor = '#444'}
                onMouseLeave={e => e.currentTarget.style.backgroundColor = selectedTemplate?.id === template.id ? '#444' : '#333'}
              >
                {/* Nome do modelo em verde */}
                <strong style={{ color: '#32CD32' }}>{template.name}</strong>
                <ReactMarkdown className="mt-2 text-gray-300">{template.content}</ReactMarkdown>
              </li>
            ))}
          </ul>
        )}

        {/* Botão "Enviar" ajustado para seguir o padrão do botão "Cadastrar Contato" */}
        <button
          onClick={() => {
            if (selectedTemplate) {
              onSelect(selectedTemplate);
            }
          }}
          disabled={!selectedTemplate}
          style={{
            backgroundColor: selectedTemplate ? '#242424' : '#555',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555',
            borderRadius: '6px',
            cursor: selectedTemplate ? 'pointer' : 'not-allowed',
            marginTop: '20px',
            alignSelf: 'flex-end',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Enviar template selecionado"
        >
          <Send size={20} />
          Enviar
        </button>
      </div>
    </div>,
    document.body
  );
};

TemplateSelector.propTypes = {
  templates: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired, // Adicione a prop `loading`
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ChatInterface = () => {
  // Estados e referências
  const [inputMessage, setInputMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [file, setFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [contactSearchTerm, setContactSearchTerm] = useState('');
  const [selectedChat, setSelectedChat] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isRecorded, setIsRecorded] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [shakeEnabled, setShakeEnabled] = useState(true);
  const [messageStatus, setMessageStatus] = useState('');
  const [messageError, setMessageError] = useState(''); // Novo estado para erros de mensagens

  // Estados para cadastrar contato
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [newContactName, setNewContactName] = useState('');
  const [newContactPhone, setNewContactPhone] = useState('');

  // Estado para atualizar contato
  const [showUpdateContactModal, setShowUpdateContactModal] = useState(false);
  const [updatedContactName, setUpdatedContactName] = useState('');
  const [updatedContactPhone, setUpdatedContactPhone] = useState('');

  // Estado para deletar contato
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);

  // Estado para templates
  const [templates, setTemplates] = useState([]);
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false); // Novo estado para carregamento de templates

  const [chats, setChats] = useState([]); // Inicializar como vazio
  const [messages, setMessages] = useState([]);

  const navigate = useNavigate();
  const emojiPickerRef = useRef(null);
  const menuRef = useRef(null);
  const messagesEndRef = useRef(null);
  const mainContainerRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);
  const recordingIntervalRef = useRef(null);

  // Novo estado e referência para o menu de mensagens
  const [showMessageMenu, setShowMessageMenu] = useState(false);
  const messageMenuRef = useRef(null);

  // Estado para as abas de contatos
  const [selectedTab, setSelectedTab] = useState('Atendimentos'); // Atualizado para 'Atendimentos'

  // Definir o base URL do backend
  const BACKEND_URL = 'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT';

  // Funções auxiliares
  const sanitizeInput = (input) => {
    return input.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const playNotificationSound = () => {
    if (soundEnabled) {
      const sound = new Audio('path/to/your/notification-sound.mp3'); // Substitua pelo caminho do arquivo de som
      sound.play();
    }
  };

  const shakeScreen = () => {
    if (shakeEnabled) {
      const chatWindow = document.getElementById('chatWindow'); // Certifique-se de que o ID está correto
      if (chatWindow) {
        chatWindow.classList.add('shake');
        setTimeout(() => chatWindow.classList.remove('shake'), 500);
      }
    }
  };

  const shakeContact = (chatId) => {
    const contactElement = document.getElementById(`contact-${chatId}`);
    if (contactElement) {
      contactElement.classList.add('shake');
      setTimeout(() => contactElement.classList.remove('shake'), 500);
    }
  };

  const handleNewMessage = (chatId) => {
    playNotificationSound();
    shakeScreen();
    shakeContact(chatId);
  };

  // Definir o chat inicial selecionado quando os chats são carregados
  useEffect(() => {
    if (chats.length > 0 && !selectedChat) {
      setSelectedChat(chats[0]);
    }
  }, [chats, selectedChat]);

  // Carrega as mensagens reais quando um chat é selecionado
  useEffect(() => {
    const fetchMessages = async () => {
      if (selectedChat) {
        try {
          const idToken = await auth.currentUser.getIdToken();
          console.log('ID Token:', idToken);
          console.log('Fetching messages for chat ID:', selectedChat.id);
          const response = await axios.get(`${BACKEND_URL}/api/get-messages/${selectedChat.id}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          });

          if (response.data.messages && response.data.messages.length > 0) {
            setMessages(response.data.messages);
            setMessageError(''); // Limpar qualquer mensagem de erro anterior
          } else {
            setMessages([]); // Define uma lista vazia sem erro
            setMessageError(''); // Limpa a mensagem de erro
          }
        } catch (error) {
          console.error('Erro ao buscar mensagens:', error);
          if (error.response) {
            if (error.response.status === 404) {
              // Nenhuma mensagem encontrada, não exibe erro
              setMessages([]); // Define a lista de mensagens como vazia
              setMessageError(''); // Limpa qualquer mensagem de erro
            } else {
              // Trata outros erros de resposta
              setMessageError(`Erro ao buscar mensagens: ${error.response.data.message || 'Erro desconhecido.'}`);
            }
          } else if (error.request) {
            setMessageError('Erro ao buscar mensagens: Nenhuma resposta do servidor.');
          } else {
            setMessageError(`Erro ao buscar mensagens: ${error.message}`);
          }
        }
      } else {
        setMessages([]);
        setMessageError('');
      }
    };

    fetchMessages();
  }, [selectedChat, BACKEND_URL]);  

  // Atualizar a busca de templates somente quando necessário
  const fetchTemplates = useCallback(async () => {
    setLoadingTemplates(true); // Inicia o carregamento
    try {
      // Para fins de teste, definimos um template fixo
      const testTemplate = {
        id: 'oferta',
        name: 'oferta',
        language: 'pt_BR',
        content: '🎉 *Black Friday na IAT Consulting!* 🎉\n\nEste mês, você ganha 10% de desconto em nossos planos! Use o cupom 112024 no momento da contratação e aproveite essa oferta imperdível! 🚀'
      };
      setTemplates([testTemplate]);

      // Caso queira utilizar a busca do backend após os testes, descomente o código abaixo:
      /*
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.get(`${BACKEND_URL}/api/get-templates`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      setTemplates(response.data.templates);
      */
    } catch (error) {
      console.error('Erro ao buscar templates:', error);
      setMessageStatus('Erro ao carregar templates.');
    } finally {
      setLoadingTemplates(false); // Finaliza o carregamento
    }
  }, [BACKEND_URL]);

  // Buscar os contatos do backend ao montar o componente
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await axios.get(`${BACKEND_URL}/api/get-chats`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });
        setChats(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar contatos:', error);
        setLoading(false);
        setMessageStatus('Erro ao buscar contatos.');
      }
    };

    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchContacts();
        // fetchTemplates(); // <-- Removido para evitar busca automática
      } else {
        navigate('/');
      }
    });
  }, [navigate, BACKEND_URL, fetchTemplates]);

  const filteredChats = contactSearchTerm
    ? chats.filter((chat) =>
        chat.name.toLowerCase().includes(contactSearchTerm.toLowerCase())
      )
    : chats;

  // Filtrar por categoria selecionada
  const filteredChatsByCategory = filteredChats.filter(chat => {
    if (selectedTab === 'Atendimentos') return chat.status === 'Atendimento';
    if (selectedTab === 'Bot') return chat.status === 'Automático';
    if (selectedTab === 'Arquivados') return chat.status === 'Arquivados';
    if (selectedTab === 'Spam') return chat.status === 'Bloqueados';
    if (selectedTab === 'Contatos') return true; // Mostrar todos os contatos
    return true;
  });

  const filteredMessages = searchTerm
    ? messages.filter((message) =>
        ((message.text || '') + (message.audioUrl ? ' [Áudio]' : '')).toLowerCase().includes(searchTerm.toLowerCase())
      )
    : messages;

  useEffect(() => {
    const handleClickOutsideEmojiPicker = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutsideEmojiPicker);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideEmojiPicker);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEmojiPicker);
    };
  }, [showEmojiPicker]);

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
      if (messageMenuRef.current && !messageMenuRef.current.contains(event.target)) {
        setShowMessageMenu(false);
      }
    };

    if (showMenu || showMessageMenu) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [showMenu, showMessageMenu]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filteredMessages, showEmojiPicker]);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  // Limpar messageError quando selectedChat muda
  useEffect(() => {
    setMessageError('');
  }, [selectedChat]);

  const handleSettingsClick = useCallback(() => {
    navigate('/settings');
  }, [navigate]);

  const handleLogout = useCallback(async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Erro ao sair:', error);
      alert('Erro ao sair. Tente novamente.');
    }
  }, [navigate]);

  const sendMessage = useCallback(async () => {
    if (inputMessage.trim() && selectedChat) {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await axios.post(`${BACKEND_URL}/send-message`, {
          phoneNumber: selectedChat.phoneNumber,
          message: sanitizeInput(inputMessage),
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });

        if (response.data.status === 'success') {
          console.log('Mensagem enviada via WhatsApp com sucesso');
          // Atualizar as mensagens localmente
          const newMessage = {
            id: Math.random(),
            text: inputMessage,
            sender: 'me',
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setInputMessage('');
        } else {
          console.error('Erro ao enviar a mensagem via WhatsApp:', response.data.message);
          setMessageStatus('Erro ao enviar a mensagem via WhatsApp: ' + response.data.message);
        }
      } catch (error) {
        console.error('Erro ao enviar a mensagem via WhatsApp:', error);
        setMessageStatus('Erro ao enviar a mensagem via WhatsApp. Tente novamente.');
      }
    }
  }, [inputMessage, selectedChat, BACKEND_URL]);

  // Função para enviar mensagem via template
  const sendTemplateMessage = useCallback(async (template) => {
    if (selectedChat && template) {
      console.log('Selected Chat:', selectedChat); // Para depuração
      try {
        const idToken = await auth.currentUser.getIdToken();
        const payload = {
          phoneNumber: selectedChat.phoneNumber,
          messageType: 'template',
          templateName: template.name,
        };
        console.log('Payload being sent:', payload); // Para depuração
  
        const response = await axios.post(`${BACKEND_URL}/send-message`, payload, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          }
        });
  
        if (response.data.status === 'success') {
          console.log('Mensagem template enviada com sucesso');
          // Atualizar as mensagens localmente
          const newMessage = {
            id: Math.random(),
            text: template.content, // Conteúdo do template
            sender: 'me',
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setMessageStatus('Mensagem template enviada com sucesso.');
        } else {
          console.error('Erro ao enviar a mensagem template:', response.data.message);
          setMessageStatus('Erro ao enviar a mensagem template: ' + response.data.message);
        }
      } catch (error) {
        console.error('Erro ao enviar a mensagem template:', error);
        setMessageStatus('Erro ao enviar a mensagem template. Tente novamente.');
      }
    }
  }, [selectedChat, BACKEND_URL]);  

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedChat) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setMessageStatus('O arquivo é muito grande. O tamanho máximo é 5MB.');
        return;
      }

      const newMessage = {
        id: Math.random(),
        text: `Arquivo: ${selectedFile.name}`,
        fileUrl: URL.createObjectURL(selectedFile),
        sender: 'me',
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);

      setFile(selectedFile);
      event.target.value = null;
    }
  };

  const startRecording = async () => {
    if (!selectedChat) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      const recorder = new MediaRecorder(stream);
      mediaRecorderRef.current = recorder;
      audioChunksRef.current = [];

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      recorder.start();
      setIsRecording(true);
      setRecordingTime(0);
      setIsRecorded(false);

      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);
    } catch (error) {
      console.error('Erro ao acessar o microfone:', error);
      setMessageStatus('Não foi possível acessar o microfone. Por favor, verifique as permissões.');
    }
  };

  const stopRecording = (shouldSend) => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      clearInterval(recordingIntervalRef.current);

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        const audioUrl = URL.createObjectURL(audioBlob);

        if (shouldSend) {
          const newMessage = {
            id: Math.random(),
            audioUrl: audioUrl,
            sender: 'me',
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          };

          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }

        setIsRecorded(true);
        setIsRecording(false);

        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
          streamRef.current = null;
        }

        setRecordingTime(0);
      };
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  // Função para alternar o estado das notificações sonoras
  const toggleSound = () => {
    setSoundEnabled(!soundEnabled);
  };

  // Função para alternar o estado do tremor de tela e aplicar o tremor na ativação
  const toggleShake = () => {
    setShakeEnabled((prev) => {
      const newState = !prev;

      if (newState && mainContainerRef.current) {
        mainContainerRef.current.classList.add('shake');
        setTimeout(() => mainContainerRef.current.classList.remove('shake'), 500);
      }

      return newState;
    });
  };

  // Funções para manipular o menu de mensagens
  const handleFinalizarAtendimento = () => {
    setShowMessageMenu(false);
    setMessageStatus('Atendimento finalizado.');
  };

  const handleOcultarAtendimento = () => {
    setShowMessageMenu(false);
    setMessageStatus('Atendimento ocultado.');
  };

  const handleObservacao = () => {
    setShowMessageMenu(false);
    setMessageStatus('Observação adicionada.');
  };

  // Função para o botão "Transcrição de Áudio"
  const handleTranscricaoAudio = () => {
    setShowMessageMenu(false);
    setMessageStatus('Transcrição de áudio iniciada.');
  };

  // Função para adicionar um novo contato à lista de chats via backend
  const addNewContact = async () => {
    if (newContactName.trim() && newContactPhone.trim()) {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await axios.post(`${BACKEND_URL}/api/add-contact`, {
          name: newContactName,
          phoneNumber: `${newContactPhone}`, // Usa o telefone completo sem adicionar dialCode
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });

        if (response.status === 201) {
          const newContact = response.data.contact;
          setChats((prevChats) => [...prevChats, newContact]);
          setShowAddContactModal(false);
          setNewContactName('');
          setNewContactPhone('');
          setMessageStatus('Contato cadastrado com sucesso.');
        } else {
          setMessageStatus('Erro ao cadastrar contato.');
        }
      } catch (error) {
        console.error('Erro ao cadastrar contato:', error);
        if (error.response) {
          console.log('Detalhes da resposta de erro:', error.response.data); // Log para depuração

          if (error.response.status === 400 && error.response.data.message?.toLowerCase().includes('já está cadastrado')) {
            setMessageStatus('Este número de telefone já está cadastrado.');
          } else if (error.response.data.message) {
            setMessageStatus(`Erro: ${error.response.data.message}`);
          } else {
            setMessageStatus('Este número de telefone já está cadastrado.');
          }
        } else {
          setMessageStatus('Erro ao cadastrar contato. Tente novamente.');
        }
      }
    } else {
      setMessageStatus('Preencha todos os campos para cadastrar o contato.');
    }
  };

  // Função para apagar contato
  const handleApagarContato = (chatId) => {
    const chatToDelete = chats.find(chat => chat.id === chatId);
    if (!chatToDelete) {
      setMessageStatus('Contato não encontrado para apagar.');
      return;
    }

    setContactToDelete(chatToDelete);
    setShowDeleteContactModal(true);
  };

  const confirmDeleteContact = async () => {
    if (!contactToDelete) return;

    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.post(
        `${BACKEND_URL}/api/delete-contact`,
        { contact_id: contactToDelete.id },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Remover o contato do estado 'chats'
        setChats((prevChats) => prevChats.filter(chat => chat.id !== contactToDelete.id));

        // Selecionar o próximo chat disponível, se houver
        setSelectedChat((prevSelectedChat) => {
          if (prevSelectedChat && prevSelectedChat.id === contactToDelete.id) {
            const remainingChats = chats.filter(chat => chat.id !== contactToDelete.id);
            return remainingChats.length > 0 ? remainingChats[0] : null;
          }
          return prevSelectedChat;
        });

        // Limpar a mensagem de status
        setMessageStatus('');
      } else {
        setMessageStatus('Erro ao apagar contato.');
      }
    } catch (error) {
      console.error('Erro ao apagar contato:', error);
      if (error.response?.data?.error) {
        setMessageStatus(`Erro ao apagar contato: ${error.response.data.error}`);
      } else {
        setMessageStatus('Erro ao apagar contato. Tente novamente.');
      }
    } finally {
      setShowDeleteContactModal(false);
      setContactToDelete(null);
    }
  };

  const cancelDeleteContact = () => {
    setShowDeleteContactModal(false);
    setContactToDelete(null);
  };

  // Função para abrir o modal de atualização de contato
  const handleOpenUpdateModal = () => {
    if (selectedChat) {
      setUpdatedContactName(selectedChat.name);
      // Extração correta do dialCode para atualizar o contato
      const dialCodeMatch = selectedChat.phoneNumber.match(/^\+(\d+)/);
      const dialCode = dialCodeMatch ? dialCodeMatch[1] : '55'; // Extrai o dialCode ou usa '55' como padrão
      const updatedPhone = selectedChat.phoneNumber.replace(`+${dialCode}`, '');
      setUpdatedContactPhone(updatedPhone); // Armazena o telefone sem o dialCode
      setShowUpdateContactModal(true);
    }
  };

  // Função para atualizar o contato
  const updateContact = async () => {
    if (updatedContactName.trim() && updatedContactPhone.trim()) {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await axios.post(`${BACKEND_URL}/api/update-contact`, {
          contact_id: selectedChat.id,
          name: updatedContactName,
          phoneNumber: `${updatedContactPhone}`, // Usa o telefone completo sem adicionar dialCode
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });

        if (response.status === 200) {
          // Atualizar o contato no estado 'chats'
          setChats((prevChats) => prevChats.map(chat => {
            if (chat.id === selectedChat.id) {
              return { ...chat, name: updatedContactName, phoneNumber: `${updatedContactPhone}` };
            } else {
              return chat;
            }
          }));
          setSelectedChat((prevSelectedChat) => ({
            ...prevSelectedChat,
            name: updatedContactName,
            phoneNumber: `${updatedContactPhone}`,
          }));
          setShowUpdateContactModal(false);
          setMessageStatus('Contato atualizado com sucesso.');
        } else {
          setMessageStatus('Erro ao atualizar contato.');
        }
      } catch (error) {
        console.error('Erro ao atualizar contato:', error);
        setMessageStatus('Erro ao atualizar contato. Tente novamente.');
      }
    } else {
      setMessageStatus('Preencha todos os campos para atualizar o contato.');
    }
  };

  // Função para arquivar contato
  const handleArquivarContato = (chatId) => {
    const chatToArchive = chats.find(chat => chat.id === chatId);
    if (!chatToArchive) {
      setMessageStatus('Contato não encontrado para arquivar.');
      return;
    }

    // Atualizar o status do contato para "Arquivados"
    archiveContact(chatId);
  };

  // Função para enviar a atualização para o backend (Arquivar)
  const archiveContact = async (chatId) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.post(`${BACKEND_URL}/api/archive-contact`, {
        contact_id: chatId,
      }, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Atualizar o estado local
        setChats((prevChats) =>
          prevChats.map(chat =>
            chat.id === chatId ? { ...chat, status: 'Arquivados' } : chat
          )
        );

        // Se o contato arquivado estava selecionado, desmarcar
        if (selectedChat && selectedChat.id === chatId) {
          setSelectedChat(null);
        }

        setMessageStatus('Contato arquivado com sucesso.');
      } else {
        setMessageStatus('Erro ao arquivar contato.');
      }
    } catch (error) {
      console.error('Erro ao arquivar contato:', error);
      if (error.response?.data?.error) {
        setMessageStatus(`Erro ao arquivar contato: ${error.response.data.error}`);
      } else {
        setMessageStatus('Erro ao arquivar contato. Tente novamente.');
      }
    }
  };

  // Função para bloquear contato
  const handleBloquearContato = (chatId) => {
    const chatToBlock = chats.find(chat => chat.id === chatId);
    if (!chatToBlock) {
      setMessageStatus('Contato não encontrado para bloquear.');
      return;
    }

    // Atualizar o status do contato para "Bloqueados"
    bloquearContato(chatId);
  };

  // Função para enviar a atualização para o backend (Bloquear)
  const bloquearContato = async (chatId) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.post(`${BACKEND_URL}/api/bloquear-contato`, {
        contact_id: chatId,
      }, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Atualizar o estado local
        setChats((prevChats) =>
          prevChats.map(chat =>
            chat.id === chatId ? { ...chat, status: 'Bloqueados' } : chat
          )
        );

        // Se o contato bloqueado estava selecionado, desmarcar
        if (selectedChat && selectedChat.id === chatId) {
          setSelectedChat(null);
        }

        setMessageStatus('Contato bloqueado com sucesso.');
      } else {
        setMessageStatus('Erro ao bloquear contato.');
      }
    } catch (error) {
      console.error('Erro ao bloquear contato:', error);
      if (error.response?.data?.error) {
        setMessageStatus(`Erro ao bloquear contato: ${error.response.data.error}`);
      } else {
        setMessageStatus('Erro ao bloquear contato. Tente novamente.');
      }
    }
  };

  // Componente para Item da Lista de Chats
  const ChatListItem = React.memo(({ chat, selectedChat, setSelectedChat, handleApagarContato, handleArquivarContato, handleBloquearContato }) => {
    const [showContactMenu, setShowContactMenu] = useState(false);
    const contactMenuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (contactMenuRef.current && !contactMenuRef.current.contains(event.target)) {
          setShowContactMenu(false);
        }
      };

      if (showContactMenu) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showContactMenu]);

    // Determinar se o contato está selecionado
    const isSelected = selectedChat && selectedChat.id === chat.id;

    // Funções para manipular o hover
    const handleMouseEnter = (e) => {
      if (!isSelected) {
        e.currentTarget.style.backgroundColor = '#3a3a3a';
      }
    };

    const handleMouseLeave = (e) => {
      if (!isSelected) {
        e.currentTarget.style.backgroundColor = 'transparent';
      }
    };

    const handleSelectChat = () => {
      console.log('Selecionando chat:', chat);
      setSelectedChat(chat);
    };

    return (
      <div
        id={`contact-${chat.id}`}
        className="flex flex-col p-3 cursor-pointer border-b border-[#444654] transition-colors duration-200"
        style={{
          backgroundColor: isSelected ? '#555555' : 'transparent',
          transition: 'background-color 0.3s ease',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleSelectChat}
        aria-label={`Selecionar contato ${chat.name}`}
      >
        <div className="flex items-center">
          {chat.imageUrl ? (
            <img src={chat.imageUrl} alt={chat.name} className="rounded-full mr-3" />
          ) : (
            <User className="rounded-full mr-3 text-gray-400" size={40} />
          )}

          <div className="flex-1">
            <p className="font-semibold text-gray-200">{chat.name}</p>
            <p className="text-sm text-gray-400">{chat.lastMessage}</p>
          </div>

          <div className="flex items-center">
            {chat.unreadCount > 0 && (
              <span
                className="text-xs rounded-full px-2 py-1"
                style={{ backgroundColor: '#4c4c4d', color: 'white' }}
              >
                {chat.unreadCount}
              </span>
            )}
            <span className="text-xs text-gray-400 ml-2">
              {chat.time && !isNaN(new Date(chat.time))
                ? new Date(chat.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                : ''}
            </span>
          </div>
        </div>

        <div className="relative flex flex-col items-end">
          <button
            className="text-gray-400 mt-1"
            onClick={(e) => {
              e.stopPropagation(); // Impede a propagação para o onClick do item de chat
              setShowContactMenu((prev) => !prev);
            }}
            aria-label="Mais opções do contato"
          >
            <ChevronDown size={16} />
          </button>
          {showContactMenu && (
            <div
              ref={contactMenuRef}
              className="absolute right-0 mt-4 w-56 bg-[#242424] shadow-lg rounded-lg border border-[#444654] flex flex-col"
              style={{ zIndex: 1000 }}
            >
              {/* Arquivar */}
              <button
                className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                aria-label="Arquivar Contato"
                onClick={(e) => {
                  e.stopPropagation();
                  handleArquivarContato(chat.id);
                  setShowContactMenu(false);
                }}
              >
                <Archive size={16} className="mr-2 text-gray-200" /> Arquivar
              </button>

              {/* Bloquear */}
              <button
                className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                aria-label="Bloquear Contato"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBloquearContato(chat.id);
                  setShowContactMenu(false);
                }}
              >
                <Lock size={16} className="mr-2" /> Bloquear
              </button>

              {/* Apagar */}
              <button
                className="w-full text-left px-4 py-2 text-red-500 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                aria-label="Apagar Contato"
                onClick={(e) => {
                  e.stopPropagation();
                  handleApagarContato(chat.id);
                  setShowContactMenu(false);
                }}
              >
                <Trash size={16} className="mr-2 text-red-500" /> Apagar
              </button>
            </div>
          )}
        </div>
      </div>
    );
  });

  ChatListItem.propTypes = {
    chat: PropTypes.object.isRequired,
    selectedChat: PropTypes.object,
    setSelectedChat: PropTypes.func.isRequired,
    handleApagarContato: PropTypes.func.isRequired,
    handleArquivarContato: PropTypes.func.isRequired,
    handleBloquearContato: PropTypes.func.isRequired,
  };

  // Componente para Mensagens
  const Message = React.memo(({ text, fileUrl, audioUrl, sender, time }) => (
    <div className={`flex mb-4 ${sender === 'me' ? 'justify-end' : 'justify-start'}`}>
      <div className={`rounded-lg p-2 max-w-xs ${sender === 'me' ? 'bg-[#141414]' : 'bg-[#242424]'}`}>
        {text && <ReactMarkdown className="text-white">{text}</ReactMarkdown>}
        {fileUrl && (
          <a href={fileUrl} download className="text-blue-400 underline mt-2 block">
            Baixar arquivo
          </a>
        )}
        {audioUrl ? (
          <audio controls className="mt-2">
            <source src={audioUrl} type="audio/webm" />
            Seu navegador não suporta o elemento de áudio.
          </audio>
        ) : null}
        <span className="text-xs text-gray-300 float-right mt-1">{time}</span>
      </div>
    </div>
  ));

  Message.propTypes = {
    text: PropTypes.string,
    fileUrl: PropTypes.string,
    audioUrl: PropTypes.string,
    sender: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  };

  // Função para obter o placeholder baseado na aba selecionada
  const getSearchPlaceholder = () => {
    switch (selectedTab) {
      case 'Atendimentos':
        return 'Pesquisar conversas em atendimentos';
      case 'Bot':
        return 'Pesquisar conversas com o bot';
      case 'Arquivados':
        return 'Pesquisar conversas arquivadas';
      case 'Spam':
        return 'Pesquisar conversas spam';
      case 'Contatos':
        return 'Pesquisar contatos';
      default:
        return 'Pesquisar';
    }
  };

  // Retorno do JSX principal
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Carregando...
      </div>
    );
  }

  return (
    <div
      ref={mainContainerRef}
      className="flex h-screen bg-[#242424] text-gray-200"
    >
      {/* Interface principal */}
      {messageStatus && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 1001 }} // Define um z-index ainda mais alto para garantir que apareça na frente
        >
          <div className="bg-[#333] text-white px-6 py-4 rounded-lg flex items-center justify-between max-w-sm w-full">
            <span>{messageStatus}</span>
            <button
              onClick={() => setMessageStatus('')}
              className="ml-4 text-gray-300 hover:text-white"
              aria-label="Fechar mensagem de status"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      )}
      {/* Outras partes da interface (lista de contatos, área de mensagens, etc.) */}

      {/* Lista de Contatos */}
      <div className="w-full md:w-1/3 flex flex-col bg-[#242424] border-r border-[#444654]">
        <div className="p-4 flex items-center justify-between">
          <div className="flex items-center">
            {user ? (
              <>
                <img
                  src={user.photoURL || '/api/placeholder/40/40'}
                  alt="Perfil do Usuário"
                  className="rounded-full"
                  width={40}
                  height={40}
                />
                <span className="ml-2 text-gray-200">{user.displayName || 'Usuário'}</span>
              </>
            ) : (
              <p>Carregando...</p>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handleSettingsClick}
              className="flex items-center justify-center text-gray-400"
              aria-label="Configurações"
            >
              <Settings size={24} />
            </button>

            <div className="relative" ref={menuRef}>
              <button
                className="flex items-center justify-center text-gray-400"
                aria-label="Mais opções"
                onClick={(e) => {
                  e.stopPropagation(); // Impede a propagação para o onClick do header
                  setShowMenu(!showMenu);
                }}
              >
                <MoreVertical size={24} />
              </button>
              {showMenu && (
                <div
                  className="absolute right-0 top-full mt-4 w-72 bg-[#242424] shadow-lg rounded-lg border border-[#444654] flex flex-col"
                  style={{ zIndex: 1000 }}
                >
                  {/* Notificações Sonoras */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                    aria-label="Notificações Sonoras"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSound();
                    }}
                  >
                    {soundEnabled ? (
                      <Volume2
                        size={16}
                        className={`mr-2 transition-colors duration-300 ${
                          soundEnabled ? 'text-gray-200 opacity-100' : 'text-gray-500 opacity-50'
                        }`}
                      />
                    ) : (
                      <VolumeX
                        size={16}
                        className={`mr-2 transition-colors duration-300 ${
                          soundEnabled ? 'text-gray-200 opacity-100' : 'text-gray-500 opacity-50'
                        }`}
                      />
                    )}
                    Notificações Sonoras
                  </button>

                  {/* Vibração de Tela */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                    aria-label="Vibração de Tela"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleShake();
                    }}
                  >
                    <Vibrate
                      size={16}
                      className={`mr-2 transition-colors duration-300 ${
                        shakeEnabled ? 'text-gray-200 opacity-100' : 'text-gray-500 opacity-50'
                      }`}
                    />
                    Vibração de Tela
                  </button>

                  {/* Troca de Empresa */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                    aria-label="Troca de Empresa"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate('/troca-de-empresa');
                    }}
                  >
                    <Building size={16} className="mr-2" /> Trocar de Empresa
                  </button>

                  {/* Logout */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLogout();
                    }}
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                    aria-label="Desconectar"
                  >
                    <LogOut size={16} className="mr-2" /> Desconectar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Abas na Listagem de Contatos */}
        <div className="flex border-b border-[#444654]">
          {['Atendimentos', 'Bot', 'Arquivados', 'Spam', 'Contatos'].map(tab => (
            <button
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`py-2 px-4 focus:outline-none text-sm ${
                selectedTab === tab
                  ? 'border-b-2 border-blue-500 text-white'
                  : 'text-gray-400 hover:text-gray-200'
              }`}
              aria-label={`Mostrar contatos de ${tab}`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Campo de Pesquisa de Contatos */}
        <div className="p-2">
          <div className="relative">
            <input
              type="text"
              placeholder={getSearchPlaceholder()} // Placeholder dinâmico
              className="w-full p-2 pl-10 rounded bg-[#242424] text-gray-200 placeholder-gray-400 outline-none focus:ring-2 focus:ring-blue-500"
              aria-label={`Pesquisar ${selectedTab.toLowerCase()}`}
              value={contactSearchTerm}
              onChange={(e) => setContactSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()} // Impede a propagação para o onClick do header
            />
            <button
              className="absolute left-3 top-2.5 text-gray-400"
              aria-label="Pesquisar"
              onClick={(e) => e.stopPropagation()} // Impede a propagação para o onClick do header
            >
              <Search size={20} />
            </button>
          </div>
        </div>

        {/* Botão "Cadastrar Contato" na Aba "Contatos" */}
        {selectedTab === 'Contatos' && (
          <div className="p-2">
            <button
              onClick={() => setShowAddContactModal(true)}
              className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center rounded"
              aria-label="Cadastrar Contato"
            >
              <UserPlus size={20} className="mr-2" /> Cadastrar Contato
            </button>
          </div>
        )}

        {/* Lista de Contatos Filtrados por Categoria */}
        <div className="overflow-y-auto flex-1">
          {Array.isArray(filteredChatsByCategory) && filteredChatsByCategory.length > 0 ? (
            filteredChatsByCategory.map(chat => (
              <ChatListItem
                key={chat.id}
                chat={chat}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
                handleApagarContato={handleApagarContato}
                handleArquivarContato={handleArquivarContato} // Passando a nova função
                handleBloquearContato={handleBloquearContato} // Passando a nova função
              />
            ))
          ) : (
            <div className="text-center text-gray-400 mt-4">
              Nenhuma conversa encontrada na categoria "{selectedTab}".
            </div>
          )}
        </div>

        {/* Modal para Cadastrar Contato */}
        {showAddContactModal &&
          ReactDOM.createPortal(
            <div style={modalOverlayStyle}>
              <div style={modalContentStyle}>
                {/* Botão "X" para fechar a modal */}
                <button
                  onClick={() => setShowAddContactModal(false)}
                  style={closeButtonStyle}
                  aria-label="Fechar cadastro de contato"
                >
                  <X size={20} />
                </button>

                <h2 style={{ textAlign: 'center', color: '#fff', marginBottom: '20px' }}>
                  Cadastrar Novo Contato
                </h2>

                <div style={{ marginBottom: '20px' }}>
                  <label htmlFor="contact-name" style={labelStyle}>
                    Nome
                  </label>
                  <input
                    id="contact-name"
                    type="text"
                    placeholder="Nome"
                    value={newContactName}
                    onChange={(e) => setNewContactName(e.target.value)}
                    style={inputStyle}
                    aria-label="Nome do novo contato"
                  />
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <label htmlFor="contact-phone" style={labelStyle}>
                    Telefone
                  </label>
                  <PhoneInput
                    country={'br'}
                    value={newContactPhone}
                    onChange={(phone, countryData) => {
                      setNewContactPhone(phone); // Armazena o número completo, incluindo o dialCode
                    }}
                    countryCodeEditable={false}
                    enableSearch={true}
                    disableDropdown={false}
                    onlyCountries={ONLY_COUNTRIES} // Usando a lista completa de países
                    containerClass="phone-input-container"
                    inputClass="phone-input-field"
                    dropdownClass="phone-input-dropdown"
                    aria-label="Telefone do novo contato"
                  />
                </div>

                {/* Botão "Cadastrar" */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <button
                    onClick={addNewContact}
                    style={{
                      backgroundColor: '#242424',
                      color: '#e5e7eb',
                      padding: '10px 20px',
                      border: '1px solid #555',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontSize: '16px',
                      transition: 'background-color 0.3s, border 0.3s',
                    }}
                    aria-label="Cadastrar contato"
                  >
                    <UserPlus size={20} />
                    Cadastrar
                  </button>
                </div>
              </div>
            </div>,
            document.body
          )}

        {/* Modal para confirmar exclusão de contato */}
        {showDeleteContactModal &&
          ReactDOM.createPortal(
            <div style={modalOverlayStyle}>
              <div style={modalContentStyle}>
                {/* Removido o botão "X" para fechar a modal */}

                <h2 style={{ textAlign: 'center', color: '#fff', marginBottom: '20px' }}>
                  Tem certeza que deseja apagar o contato "{contactToDelete.name}"?
                </h2>

                {/* Botões "Cancelar" e "Apagar" */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <button
                    onClick={cancelDeleteContact}
                    style={{
                      backgroundColor: '#242424',
                      color: '#e5e7eb',
                      padding: '10px 20px',
                      border: '1px solid #555',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontSize: '16px',
                      transition: 'background-color 0.3s, border 0.3s',
                    }}
                    aria-label="Cancelar exclusão"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={confirmDeleteContact}
                    style={{
                      backgroundColor: '#242424', // Mesma cor de fundo que "Cancelar"
                      color: '#ff4d4f', // Cor vermelha para texto e ícone
                      padding: '10px 20px',
                      border: '1px solid #555',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontSize: '16px',
                      transition: 'background-color 0.3s, border 0.3s',
                    }}
                    aria-label="Apagar contato"
                  >
                    <Trash size={20} className="text-red-500" />
                    Apagar
                  </button>
                </div>
              </div>
            </div>,
            document.body
          )}

        {/* Modal para Atualizar Contato */}
        {showUpdateContactModal &&
          ReactDOM.createPortal(
            <div style={modalOverlayStyle}>
              <div style={modalContentStyle}>
                {/* Botão "X" para fechar a modal */}
                <button
                  onClick={() => setShowUpdateContactModal(false)}
                  style={closeButtonStyle}
                  aria-label="Fechar atualização de contato"
                >
                  <X size={20} />
                </button>

                <h2 style={{ textAlign: 'center', color: '#fff', marginBottom: '20px' }}>
                  Atualizar Contato
                </h2>

                <div style={{ marginBottom: '20px' }}>
                  <label htmlFor="contact-name-update" style={labelStyle}>
                    Nome
                  </label>
                  <input
                    id="contact-name-update"
                    type="text"
                    placeholder="Nome"
                    value={updatedContactName}
                    onChange={(e) => setUpdatedContactName(e.target.value)}
                    style={inputStyle}
                    aria-label="Nome do contato atualizado"
                  />
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <label htmlFor="contact-phone-update" style={labelStyle}>
                    Telefone
                  </label>
                  <PhoneInput
                    country={'br'}
                    value={updatedContactPhone}
                    onChange={(phone, countryData) => {
                      setUpdatedContactPhone(phone); // Armazena o número completo, incluindo o dialCode
                    }}
                    countryCodeEditable={false}
                    enableSearch={true}
                    disableDropdown={false}
                    onlyCountries={ONLY_COUNTRIES} // Usando a lista completa de países
                    containerClass="phone-input-container"
                    inputClass="phone-input-field"
                    dropdownClass="phone-input-dropdown"
                    aria-label="Telefone do contato atualizado"
                  />
                </div>

                {/* Botão "Atualizar" ajustado para seguir o padrão do botão "Cadastrar Contato" */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <button
                    onClick={updateContact}
                    style={{
                      backgroundColor: '#242424',
                      color: '#e5e7eb',
                      padding: '10px 20px',
                      border: '1px solid #555',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontSize: '16px',
                      transition: 'background-color 0.3s, border 0.3s',
                    }}
                    aria-label="Atualizar contato"
                  >
                    <Pen size={20} />
                    Atualizar
                  </button>
                </div>
              </div>
            </div>,
            document.body
          )}

        {/* Modal para Seleção de Templates */}
        {showTemplateSelector && (
          <TemplateSelector
            templates={templates}
            loading={loadingTemplates} // Passa o estado de carregamento
            onSelect={(template) => {
              sendTemplateMessage(template);
              setShowTemplateSelector(false);
            }}
            onClose={() => setShowTemplateSelector(false)}
          />
        )}
      </div>

      {/* Área de Chat */}
      <div
        className="flex-1 flex flex-col relative"
        style={{
          backgroundColor: '#4c4c4d',
          backgroundImage: `url(${iconBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '300px 300px',
        }}
      >
        {/* Cabeçalho do Chat */}
        <div
          className="p-4 bg-[#242424] flex items-center justify-between relative h-16"
        >
          <div className="flex items-center space-x-4" onClick={handleOpenUpdateModal} style={{ cursor: 'pointer' }}>
            {selectedChat && (
              <>
                <div className="rounded-full w-10 h-10 bg-gray-600 flex items-center justify-center">
                  {selectedChat.imageUrl ? (
                    <img 
                      src={selectedChat.imageUrl} 
                      alt={selectedChat.name} 
                      className="rounded-full w-full h-full object-cover" 
                    />
                  ) : (
                    <User size={24} className="text-white" />
                  )}
                </div>
                <span className="text-gray-200">{selectedChat.name}</span>
              </>
            )}
          </div>
          <div className="flex items-center space-x-4 text-gray-400">
            {/* Campo de Pesquisa de Mensagens */}
            <div className="relative">
              <input
                type="text"
                placeholder="Pesquisar mensagens"
                className="w-64 p-2 pl-10 rounded bg-[#242424] text-gray-200 placeholder-gray-400 outline-none focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                aria-label="Pesquisar mensagens"
                onClick={(e) => e.stopPropagation()} // Impede a propagação para o onClick do header
              />
              <button
                className="absolute left-3 top-2.5 text-gray-400"
                aria-label="Pesquisar"
                onClick={(e) => e.stopPropagation()} // Impede a propagação para o onClick do header
              >
                <Search size={20} />
              </button>
            </div>

            {/* Botão de três pontos para opções de atendimento */}
            <div className="relative" ref={messageMenuRef}>
              <button
                className="flex items-center justify-center text-gray-400"
                aria-label="Mais opções de atendimento"
                onClick={(e) => {
                  e.stopPropagation(); // Impede a propagação para o onClick do header
                  setShowMessageMenu(!showMessageMenu);
                }}
              >
                <MoreVertical size={24} />
              </button>
              {showMessageMenu && (
                <div
                  className="absolute right-0 top-full mt-4 w-72 bg-[#242424] shadow-lg rounded-lg border border-[#444654] flex flex-col"
                  style={{ zIndex: 1000 }}
                >
                  {/* Botão "Enviar Modelo de Mensagem" com novo ícone de envio */}
                  <button
                    className={`w-full text-left px-4 py-2 text-gray-200 flex items-center whitespace-nowrap hover:bg-[#3a3a3a] ${
                      !selectedChat ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    aria-label="Enviar Modelo de Mensagem"
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (selectedChat) {
                        await fetchTemplates(); // Chama a função para buscar os templates (neste caso, define o template fixo)
                        setShowTemplateSelector(true); // Exibe o seletor de templates
                      }
                    }}
                    disabled={!selectedChat}
                  >
                    <Send size={16} className="mr-2" /> Enviar Modelo de Mensagem
                  </button>
                  
                  {/* Novo Botão "Transferir Atendimento" */}
                  <button
                    className={`w-full text-left px-4 py-2 text-gray-200 flex items-center whitespace-nowrap hover:bg-[#3a3a3a] ${
                      !selectedChat ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    aria-label="Transferir Atendimento"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selectedChat) {
                        // Implementar a lógica de transferência aqui
                        setMessageStatus('Atendimento transferido com sucesso.');
                      }
                    }}
                    disabled={!selectedChat}
                  >
                    <FaExchangeAlt size={16} className="mr-2" /> Transferir Atendimento
                  </button>

                  {/* Finalizar Atendimento */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                    aria-label="Finalizar atendimento"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFinalizarAtendimento();
                    }}
                  >
                    <CheckCircle size={16} className="mr-2" /> Finalizar Atendimento
                  </button>

                  {/* Observação */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                    aria-label="Observação"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleObservacao();
                    }}
                  >
                    <Pen size={16} className="mr-2" /> Observação
                  </button>
                  
                  {/* Transcrição de Áudio */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#3a3a3a] flex items-center whitespace-nowrap"
                    aria-label="Transcrição de Áudio"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTranscricaoAudio();
                    }}
                  >
                    <Mic2 size={16} className="mr-2" /> Transcrição de Áudio
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mensagem de Status Centralizada - Apenas para erros de mensagens */}
        {typeof messageError === 'string' && messageError.toLowerCase().startsWith('erro') && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ zIndex: 1002 }} // Define um z-index ainda mais alto para garantir que apareça na frente
          >
            <div className="bg-[#333] text-white px-6 py-4 rounded-lg flex items-center justify-between max-w-sm w-full">
              <span>{messageError}</span>
              <button
                onClick={() => setMessageError('')}
                className="ml-4 text-gray-300 hover:text-white"
                aria-label="Fechar mensagem de erro"
              >
                <X size={16} />
              </button>
            </div>
          </div>
        )}

        {/* Área de Mensagens */}
        <div 
          className="flex-1 overflow-y-auto p-4 relative" 
          id="chatWindow" 
          style={{
            backgroundColor: '#4c4c4d',
            backgroundImage: `url(${iconBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '300px 300px',
            minHeight: '300px'
          }}
        >
          {selectedChat ? (
            Array.isArray(filteredMessages) && filteredMessages.length > 0 ? (
              filteredMessages.map(message => (
                <Message key={message.id} {...message} />
              ))
            ) : (
              <div className="text-center text-gray-200 mt-4">
                Nenhuma mensagem encontrada.
              </div>
            )
          ) : (
            <div className="text-center text-gray-200 mt-4">
              Selecione uma conversa para começar
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Emoji Picker */}
        {showEmojiPicker && (
          <div
            className="absolute left-0 right-0"
            style={{
              bottom: '64px',
              height: '50%',
              overflowY: 'auto',
              zIndex: 50,
            }}
            ref={emojiPickerRef}
          >
            <EmojiPicker
              theme="dark"
              onEmojiClick={(emojiData) => {
                setInputMessage((prev) => prev + emojiData.emoji);
              }}
              emojiStyle="native"
              skinTonesDisabled
              searchDisabled
              previewConfig={{ showPreview: false }}
              width="100%"
              height="100%"
            />
          </div>
        )}

        {/* Campo de Entrada de Mensagem */}
        <div className="p-4 bg-[#242424] flex items-center">
          <div className="flex space-x-4">
            <div className="relative">
              <button
                className="flex items-center justify-center text-gray-400"
                onClick={toggleEmojiPicker}
                aria-label="Adicionar emoji"
              >
                {showEmojiPicker ? <X size={24} /> : <Smile size={24} />}
              </button>
            </div>
            <button className="flex items-center justify-center text-gray-400" aria-label="Anexar arquivo">
              <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                <Paperclip size={24} />
              </label>
              <input
                id="file-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
            </button>
            {/* Adicionando o ícone de AI */}
            <button className="flex items-center justify-center text-gray-400" aria-label="AI">
              <Sparkles size={24} />
            </button>
          </div>

          {/* Indicador de Gravação */}
          {isRecording && (
            <div className="flex items-center space-x-2 text-gray-200 ml-4">
              <div>{`${Math.floor(recordingTime / 60)}:${('0' + (recordingTime % 60)).slice(-2)}`}</div>
              <button
                className="flex items-center justify-center text-green-400"
                onClick={() => stopRecording(true)}
                aria-label="Parar e enviar gravação"
              >
                <CheckCircle size={24} />
              </button>
              <button
                className="flex items-center justify-center text-red-400"
                onClick={() => stopRecording(false)}
                aria-label="Cancelar gravação"
              >
                <XCircle size={24} />
              </button>
            </div>
          )}

          {/* Campo de Texto para Mensagens */}
          {!isRecording && (
            <input
              type="text"
              placeholder="Digite uma mensagem"
              className="flex-1 p-2 rounded bg-[#242424] text-gray-200 placeholder-gray-400 mx-4 outline-none focus:ring-2 focus:ring-blue-500"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') sendMessage();
              }}
              aria-label="Digite uma mensagem"
              disabled={!selectedChat}
              onClick={(e) => e.stopPropagation()} // Impede a propagação para o onClick do header
            />
          )}

          {/* Botão de Gravação de Áudio */}
          {!isRecording && inputMessage === '' && (
            <button
              className={`ml-2 flex items-center justify-center text-gray-400 ${!selectedChat ? 'opacity-50 cursor-not-allowed' : ''}`}
              onMouseDown={startRecording}
              aria-label="Gravar mensagem de áudio"
              disabled={!selectedChat}
            >
              <Mic size={24} />
            </button>
          )}

          {/* Botão de Enviar Mensagem */}
          {inputMessage && !isRecording && (
            <button
              className="ml-2 flex items-center justify-center text-gray-400"
              onClick={sendMessage}
              aria-label="Enviar mensagem"
              disabled={!selectedChat}
            >
              <Send size={24} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

// Estilos reutilizáveis
const tableHeaderStyle = {
  borderBottom: '2px solid #444654',
  padding: '10px',
  textAlign: 'left',
};

const tableRowStyle = {
  borderBottom: '1px solid #444654',
  cursor: 'pointer', // Linha clicável
  transition: 'background-color 0.2s',
};

const tableCellStyle = {
  padding: '10px',
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: '#242424',
  padding: '30px',
  borderRadius: '12px',
  width: '90%',
  maxWidth: '500px',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  position: 'relative', // Para posicionar o botão "X"
};

const detailModalContentStyle = {
  backgroundColor: '#242424',
  padding: '30px',
  borderRadius: '12px',
  width: '90%',
  maxWidth: '500px',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  position: 'relative', // Para posicionar o botão "X"
};

const closeButtonStyle = {
  position: 'absolute',
  top: '15px',
  right: '15px',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
};

const labelStyle = {
  display: 'block',
  marginBottom: '8px',
  color: '#e5e7eb',
  fontSize: '16px',
};

const inputStyle = {
  width: '100%',
  padding: '12px',
  borderRadius: '6px',
  border: '1px solid #555',
  backgroundColor: '#333',
  color: '#fff',
  fontSize: '16px',
};

export default ChatInterface;
