// src/components/WhatsAppConfig.js

import React, { useState, useEffect } from 'react';
import { Edit, MessageSquare, Plus, Loader2 } from 'lucide-react'; // Importação dos ícones necessários
import { getAuth } from 'firebase/auth'; // Importar getAuth para obter o token

const WhatsAppConfig = ({ setCurrentView }) => {
  // Estados para armazenar as configurações do WhatsApp
  const [cadastro, setCadastro] = useState({
    nome: '',
    numeroConectado: '',
    limitesMensagens: '',
    statusNumero: '',
    wabaId: '',
    verificacaoEmpresarial: '',
    statusConta: '',
    linhaCredito: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [dataFetched, setDataFetched] = useState(false); // Estado para controlar se os dados foram buscados

  // Estilos compartilhados para inputs
  const sharedStyles = {
    backgroundColor: '#333',
    color: '#fff',
    padding: '12px',
    borderRadius: '6px',
    border: '1px solid #555',
    width: '100%',
    fontSize: '14px',
    cursor: 'not-allowed',
    boxSizing: 'border-box',
    height: '44px', // Garantir altura consistente
  };

  // Função para obter o token do Firebase
  const getFirebaseToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken();
    }
    throw new Error('Usuário não autenticado.');
  };

  // Função para buscar as configurações do WhatsApp do backend
  const fetchWhatsAppConfig = async () => {
    try {
      setLoading(true);
      const firebaseToken = await getFirebaseToken();

      const response = await fetch('https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/get-whatsapp-config', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${firebaseToken}`, // Incluir o token no cabeçalho
        },
      });

      const data = await response.json();

      if (response.ok) {
        setCadastro(data.whatsappConfig);
        setMessage({ type: 'success', text: 'Configurações carregadas com sucesso!' });
        setDataFetched(true);
      } else {
        setMessage({ type: 'error', text: data.error || 'Erro ao carregar as configurações.' });
      }
    } catch (error) {
      console.error('Erro:', error);
      setMessage({ type: 'error', text: 'Ocorreu um erro ao carregar as configurações.' });
    } finally {
      setLoading(false);
    }
  };

  // Função para iniciar o fluxo de Cadastro Incorporado
  const iniciarCadastroIncorporado = () => {
    launchWhatsAppSignup();
  };

  // Função para iniciar o fluxo de Embedded Signup via SDK
  const launchWhatsAppSignup = () => {
    const configId = '1237469174054721'; // Substitua pelo seu Configuration ID

    // Verificar se o SDK do Facebook está carregado
    if (window.FB) {
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const code = response.authResponse.code;
            console.log('Código recebido:', code);
            enviarCodigoParaBackend(code);
          } else {
            setMessage({ type: 'error', text: 'Falha na autenticação.' });
          }
        },
        {
          config_id: configId, // Seu Configuration ID
          response_type: 'code',
          override_default_response_type: true,
          extras: {
            setup: {},
            featureType: '',
            sessionInfoVersion: '3',
          },
        }
      );
    } else {
      setMessage({ type: 'error', text: 'SDK do Facebook não carregado. Por favor, tente novamente mais tarde.' });
      console.error('SDK do Facebook não carregado.');
    }
  };

  // Função para enviar o código para o backend
  const enviarCodigoParaBackend = async (code) => {
    try {
      setLoading(true);
      const firebaseToken = await getFirebaseToken(); // Obter o token do usuário autenticado

      const response = await fetch('https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/processar-cadastro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${firebaseToken}`, // Incluir o token no cabeçalho
        },
        body: JSON.stringify({ code }),
      });

      const data = await response.json();

      if (response.ok) { // Verifica se a resposta foi bem-sucedida
        setMessage({ type: 'success', text: 'Cadastro realizado com sucesso! Carregando configurações...' });
        await fetchWhatsAppConfig(); // Buscar as configurações após o cadastro
      } else {
        setMessage({ type: 'error', text: data.error || 'Erro ao processar o cadastro.' });
      }
    } catch (error) {
      console.error('Erro:', error);
      setMessage({ type: 'error', text: 'Ocorreu um erro ao processar o cadastro.' });
    } finally {
      setLoading(false);
    }
  };

  // Listener para mensagens do Facebook (Embedded Signup)
  useEffect(() => {
    const messageListener = (event) => {
      // Verificar a origem para segurança conforme a documentação
      const allowedOrigins = ["https://www.facebook.com", "https://web.facebook.com"];
      if (!allowedOrigins.includes(event.origin)) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            console.log("ID do Número de Telefone:", phone_number_id, "ID da Conta WABA:", waba_id);
            enviarIDsParaBackend(phone_number_id, waba_id);
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            setMessage({ type: 'error', text: `Cadastro cancelado na etapa: ${current_step}` });
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            setMessage({ type: 'error', text: `Erro no cadastro: ${error_message}` });
          }
        }
      } catch (error) {
        console.error('Erro ao processar a mensagem do fluxo:', error);
      }
    };

    window.addEventListener('message', messageListener, false);

    return () => {
      window.removeEventListener('message', messageListener, false);
    };
  }, []);

  // Função para enviar IDs para o backend
  const enviarIDsParaBackend = async (phoneNumberId, wabaId) => {
    try {
      setLoading(true);
      const firebaseToken = await getFirebaseToken(); // Obter o token do usuário autenticado

      const response = await fetch('https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/receber-ids', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${firebaseToken}`, // Incluir o token no cabeçalho
        },
        body: JSON.stringify({ phone_number_id: phoneNumberId, waba_id: wabaId }),
      });

      const data = await response.json();

      if (response.ok) { // Verifica se a resposta foi bem-sucedida
        setMessage({ type: 'success', text: 'IDs recebidos e processados com sucesso! Carregando configurações...' });
        await fetchWhatsAppConfig(); // Buscar as configurações após receber os IDs
      } else {
        setMessage({ type: 'error', text: data.error || 'Erro ao processar os IDs.' });
      }
    } catch (error) {
      console.error('Erro:', error);
      setMessage({ type: 'error', text: 'Ocorreu um erro ao processar os IDs.' });
    } finally {
      setLoading(false);
    }
  };

  // Função para editar o perfil do WhatsApp
  const handleEditarPerfil = () => {
    // Implementar a lógica para editar o perfil do WhatsApp
    // Por exemplo, navegar para outra página ou abrir um modal
    console.log('Editar Perfil clicado');
    // Exemplo: setCurrentView('editarPerfil');
  };

  // Função para redirecionar para Modelos de Mensagem
  const handleModelosMensagem = () => {
    // Utiliza a função passada como prop para mudar a visualização
    setCurrentView('templateConfig');
    console.log('Redirecionando para TemplateConfig');
  };

  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '1200px', // Ajustado para acomodar melhor os elementos
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px', // Espaçamento entre seções
        margin: '0 auto', // Centraliza no container
      }}
    >
      {/* Cabeçalho com botão "Adicionar" */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          onClick={iniciarCadastroIncorporado}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555', // Adicionado border
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Adicionar Configuração"
        >
          <Plus size={20} /> {/* Ícone de Adicionar */}
          Adicionar
        </button>
      </div>

      {/* Cabeçalho de Seção */}
      <h2 style={{ textAlign: 'center', fontSize: '16px', margin: '0' }}>
        Conta do WhatsApp
      </h2>

      {/* Formulário de Configuração (Campos Não Editáveis) */}
      <form style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {/* Primeira Linha de Campos */}
        <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          {/* Nome */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="nome"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Nome
            </label>
            <input
              type="text"
              id="nome"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Nome não disponível'}
              value={cadastro.nome || ''}
              disabled
              style={{
                ...sharedStyles,
              }}
            />
          </div>

          {/* Número Conectado */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="numeroConectado"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Número Conectado
            </label>
            <input
              type="tel"
              id="numeroConectado"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Número não disponível'}
              value={cadastro.numeroConectado || ''}
              disabled
              style={{
                ...sharedStyles,
              }}
            />
          </div>

          {/* Limites de Mensagens */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="limitesMensagens"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Limites de Mensagens
            </label>
            <input
              type="number"
              id="limitesMensagens"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Limite não disponível'}
              value={cadastro.limitesMensagens || ''}
              disabled
              min="0"
              style={{
                ...sharedStyles,
              }}
            />
          </div>

          {/* Status do Número */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="statusNumero"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Status do Número
            </label>
            <input
              type="text"
              id="statusNumero"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Status do Número não disponível'}
              value={cadastro.statusNumero || ''}
              disabled
              style={{
                ...sharedStyles,
              }}
            />
          </div>
        </div>

        {/* Segunda Linha de Campos */}
        <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          {/* ID da Conta (wabaId) */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="wabaId"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              ID da Conta (WABA)
            </label>
            <input
              type="text"
              id="wabaId"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'ID não disponível'}
              value={cadastro.wabaId || ''}
              disabled
              style={{
                ...sharedStyles,
              }}
            />
          </div>

          {/* Verificação Empresarial */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="verificacaoEmpresarial"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Verificação Empresarial
            </label>
            <input
              type="text"
              id="verificacaoEmpresarial"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Verificação Empresarial não disponível'}
              value={cadastro.verificacaoEmpresarial || ''}
              disabled
              style={{
                ...sharedStyles,
              }}
            />
          </div>

          {/* Status da Conta */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="statusConta"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Status da Conta
            </label>
            <input
              type="text"
              id="statusConta"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Status da Conta não disponível'}
              value={cadastro.statusConta || ''}
              disabled
              style={{
                ...sharedStyles,
              }}
            />
          </div>

          {/* Linha de Crédito */}
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label
              htmlFor="linhaCredito"
              style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}
            >
              Linha de Crédito
            </label>
            <input
              type="text"
              id="linhaCredito"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Linha de crédito não disponível'}
              value={cadastro.linhaCredito || ''}
              disabled
              style={{
                ...sharedStyles,
              }}
            />
          </div>
        </div>

        {/* Botões Adicionais */}
        <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
          {/* Botão: Editar Perfil */}
          <button
            type="button"
            onClick={handleEditarPerfil}
            style={{
              backgroundColor: '#242424',
              color: '#e5e7eb',
              padding: '10px 20px',
              border: '1px solid #555',
              borderRadius: '6px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '14px',
              transition: 'background-color 0.3s, border 0.3s',
              width: '220px',
            }}
            aria-label="Editar Perfil"
          >
            <Edit size={20} /> {/* Ícone de Editar */}
            Editar Perfil
          </button>

          {/* Botão: Modelos de Mensagem */}
          <button
            type="button"
            onClick={handleModelosMensagem}
            style={{
              backgroundColor: '#242424',
              color: '#e5e7eb',
              padding: '10px 20px',
              border: '1px solid #555',
              borderRadius: '6px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '14px',
              transition: 'background-color 0.3s, border 0.3s',
              width: '220px',
            }}
            aria-label="Modelos de Mensagem"
          >
            <MessageSquare size={20} /> {/* Ícone de Mensagem */}
            Modelos de Mensagem
          </button>
        </div>
      </form>

      {/* Mensagem de Feedback */}
      {loading && (
        <div style={{ textAlign: 'center', fontSize: '14px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader2 className="loader" size={20} /> {/* Indicador de carregamento */}
          <span style={{ marginLeft: '10px' }}>Processando...</span>
        </div>
      )}

      {message && (
        <div
          role="alert"
          aria-live="assertive"
          style={{
            color: message.type === 'error' ? '#ff6b6b' : '#4caf50',
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
};

export default WhatsAppConfig;
